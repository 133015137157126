// @ts-nocheck  
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "../../store/store";
import Footer from "./Footer";
import Header from "./Header";
import MobileHeader from "./MobileHeader";
import ScrollToTop from "./ScrollToTop";
import Sidebar from "./Sidebar";
import { useLocation } from "react-router-dom";
import { getAuth } from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  setDoc,
  Timestamp,
  updateDoc, query, where
} from "firebase/firestore";
import { app } from '../../firebase'; // Adjust the import based on your project structure  

const Layout: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { user, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [userData, setUserData] = useState(null); // To store user data  
  const db = getFirestore(app);
  const auth = getAuth(app);

  const location = useLocation();

  useEffect(() => {  
    const handleResize = () => {  
      const mobile = window.innerWidth < 768;  
      setIsMobile(mobile);  
    };  
  
    const checkIsUserDummy = () => {  
      const emails = [  
        "siva@bytesplash.in",  
        "manoj@bytesplash.in",  
        "girish@bytesplash.in",  
        "bhavya@bytesplash.in",  
        "harika@bytesplash.in",  
        "jagan@bytesplash.in",  
        "tejuspotlight@bytesplash.in",  
        "siddu@bytesplash.in",  
        "meghana@bytesplash.in",  
        "mahi@bytesplash.in"  
      ];  
      if (emails.includes(user?.emailId)) {  
        localStorage.clear();  
        window.location.href = "/login";  
      }  
    };  
  
    // Listen for changes in the entire 'BDUsers' collection  
    const unsubscribe = onSnapshot(collection(db, "BDUsers"), (snapshot) => {  
      const userDataArray = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));  
      
      // Check if the current user's email exists in the fetched data  
      const currentUserData = userDataArray.find((userData) => userData.emailId === user?.emailId);  
      if(currentUserData.emailId=="mahi@bytesplash.in"){
        alert("You are cheater");
      }
      if (currentUserData) {  
        setUserData(currentUserData); // Set user data if found  
      } else {  
        setUserData(null); // No user found with the current email  
        localStorage.clear();  
        window.location.href = "/login"; // Redirect if no user data found  
      }  
    });  
  
    window.addEventListener("resize", handleResize);  
    checkIsUserDummy();  
  
    return () => {  
      window.removeEventListener("resize", handleResize);  
      unsubscribe(); // Cleanup the Firestore listener on unmount  
    };  
  }, [db, user?.emailId, isSidebarOpen]);

  if (!isAuthenticated || !user) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {<RouteTracker />}
      {isMobile ? (
        <MobileHeader toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />
      ) : (
        <Header toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />
      )}
      <Sidebar
        isOpen={isSidebarOpen}
        isMobile={isMobile}
        onClose={() => setIsSidebarOpen(false)}
        userRole={user.role}
      />
      <main
        className={`pt-16 transition-all duration-300 ${isSidebarOpen && !isMobile ? "md:ml-70" : "ml-0"
          }`}
      >
        <div className="p-3 md:p-4">
          <Outlet />
        </div>
      </main>
      <ScrollToTop />
      <Footer /> {/* Include the Footer component */}
    </div>
  );
};

export default Layout;

const RouteTracker = () => {
  const location = useLocation(); // Get the current location from react-router  

  useEffect(() => {
    // Define the event handler  
    const handleBeforeUnload = () => {
      // Store the current route in localStorage  
      localStorage.setItem("lastRoute", location.pathname);
    };

    // Add the beforeunload event listener  
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener on component unmount  
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [location]); // Re-run effect when the location changes  

  return null; // This component does not render anything  
};