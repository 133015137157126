import Compressor from "compressorjs";

export const loadBlobAsImage = (blob) => {
  return new Promise((resolve, reject) => {
    // Create a FileReader to read the Blob as a data URL
    const reader = new FileReader();

    reader.onload = () => {
      const img: any = new Image();
      img.src = reader.result; // Set image source from FileReader results

      img.onload = () => {
        resolve(img); // Resolve the promise with the Image object
      };

      img.onerror = () => {
        reject(new Error("Failed to load image from Blob."));
      };
    };

    reader.onerror = () => {
      reject(new Error("Failed to read Blob."));
    };

    // Read the Blob as a data URL
    reader.readAsDataURL(blob);
  });
};

export const loadImageAsBlob = (imageSrc) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous"; // Handle cross-origin images if needed
    img.src = imageSrc;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      // Convert canvas to Blob
      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error("Failed to convert image to Blob."));
          }
        },
        "image/png",
        0.8
      ); // Specify the desired quality (0.8 here)
    };

    img.onerror = () => {
      reject(new Error("Failed to load image."));
    };
  });
};

export const compressImage = (imageFile, options:any = {}) => {
    return new Promise((resolve, reject) => {
      new Compressor(imageFile, {
        quality: options.quality || 0.6, // Default quality
        maxWidth: options.maxWidth || 1000, // Default max width
        maxHeight: options.maxHeight || 1000, // Default max height
        convertSize: options.convertSize || 100000, // Default convertSize
        success: (compressedResult) => {
          resolve(compressedResult);
        },
        error(err) {
          reject(err);
        },
      });
    });
  };

