import React, { useState } from 'react';
import { Phone, Mail, Edit2, Trash2, User } from 'lucide-react';
import StatusBadge from './StatusBadge';
import { formatAmount } from '../utilities';

interface BGVCardProps {
    bgvProfile: any;
    onEdit: (bgv: any) => void;
    onDelete: (bgv: any) => void;
    isSuperAdmin: boolean;
    isBGVTeam: boolean;
}

const BGVCard: React.FC<BGVCardProps> = ({
    bgvProfile,
    onEdit,
    onDelete,
    isSuperAdmin,
    isBGVTeam
}) => {
    const [showMore, setShowMore] = useState(false);

    return (
        <div className="hover900 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-900 transform hover:scale-105 transition-all duration-900 ease-in-out">
            <div className="p-6">
                <div className="flex justify-between items-start mb-4">
                    <div className="flex items-center gap-3">
                        <div className="w-12 h-12 rounded-full bg-[#0071bd] bg-opacity-10 flex items-center justify-center">
                            <User className="w-6 h-6 text-[#0071bd]" />
                        </div>
                        <div className="flex flex-col">
                            <h3 className="text-xl font-bold text-gray-900">{bgvProfile.fullName}</h3>
                        </div>
                    </div>
                    <div className="flex gap-2">
                        <button
                            onClick={() => onEdit(bgvProfile)}
                            className="p-2 text-[#0071bd] hover:bg-[#0071bd] hover:bg-opacity-10 rounded-full transition-colors duration-300 ease-in-out transform hover:scale-110"
                        >
                            <Edit2 className="w-5 h-5" />
                        </button>
                        <button
                            onClick={() => onDelete(bgvProfile)}
                            className="p-2 text-red-600 hover:bg-red-50 rounded-full transition-colors duration-300 ease-in-out transform hover:scale-110"
                        >
                            <Trash2 className="w-5 h-5" />
                        </button>
                    </div>
                </div>

                <div className="space-y-3">
                    <div className="flex items-center gap-2 text-gray-600">
                        <Mail className="w-4 h-4" />
                        <span className="text-sm font-medium">{bgvProfile.emailId}</span>
                    </div>
                    <div className="flex items-center gap-2 text-gray-600">
                        <Phone className="w-4 h-4" />
                        <span className="text-sm font-medium">{bgvProfile.mobileNo}</span>
                    </div>

                    <div className="flex justify-between bg-gray-100 p-3 rounded-lg">
                        <div>
                            <span className="text-sm text-gray-500 font-medium">Total Amount</span>
                            <p className="mt-1 font-semibold text-gray-900">₹{formatAmount(bgvProfile.totalAmount) || 'N/A'}</p>
                        </div>
                        <div>
                            <span className="text-sm text-gray-500 font-medium">Paid Amount</span>
                            <p className="mt-1 font-semibold text-gray-900">₹{formatAmount(bgvProfile.paidAmount) || 'N/A'}</p>
                        </div>
                    </div>

                    {bgvProfile?.balanceAmount >0 ?
                        (<div>
                            <span className="text-sm text-gray-500 font-medium">Balance</span>
                            <p className="mt-1 font-semibold text-gray-900">₹{formatAmount(bgvProfile.balanceAmount) || 'N/A'}</p>
                        </div>) : null
                    }
                </div>
            </div>
        </div>
    );
};

export default BGVCard;  