import React, { useState, useRef, useEffect } from "react";
import { Download, Plus, Trash, Upload, X } from "lucide-react";
import { ExcelIcon, GreenDeleteIcon } from "../../components/Icons";
import { Profile } from "../types";
import { utils, writeFile } from "xlsx"; // Import necessary functions from SheetJS
import * as XLSX from "xlsx";
import ConfirmationPopup from "../../components/ConfirmationPopup.jsx";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  getStorage,
} from "firebase/storage";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  updateDoc,
  query,
  where,
  setDoc,
  onSnapshot,
  getDoc,
  Timestamp,
} from "firebase/firestore";
//@ts-ignore
import { app, storage } from "../../firebase";
import { getAuth } from "firebase/auth";

interface ProfileTableProps {
  setLoader: (loading: boolean) => void;
  addToast: (message: string, type: string) => void;
  profiles: Profile[];
  tempProfiles: Profile[];
  setProfiles: React.Dispatch<React.SetStateAction<Profile[]>>;
  setTempProfiles: React.Dispatch<React.SetStateAction<Profile[]>>;
  user: any;
}

const ProfileTable: React.FC<ProfileTableProps> = ({
  setLoader,
  addToast,
  profiles,
  tempProfiles,
  setProfiles,
  setTempProfiles,
  user,
}) => {
  const db = getFirestore(app);
  const auth = getAuth(app);
  const [isActive, setIsActive] = useState<boolean>(true);
  // Define a ref to hold the timeout ID
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState<any>(false);
  // Initialize state with today's date
  const [currentDateValue, setCurrentDateValue] = useState<any>(() => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  });
  const currentDateValueRef = useRef(null);
  const [selectedProfileToDelete, setSelectedProfileToDelete] =
    useState<any>(null);
  const [userLoggedIn, setuserLoggedIn] = useState<any>(user);

  const graduationOptions = [
    "B.Tech",
    "M.Tech",
    "MCA",
    "MSC",
    "B.Com",
    "M.Com",
    "Degree",
  ];

  const branchOptions = [
    "CSE",
    "IT",
    "ECE",
    "EEE",
    "Mech",
    "Civil",
    "EIE",
    "Commerce",
    "Computer Applications",
    "Statistics",
    "Accounting",
  ];

  const yearOptions = Array.from({ length: 12 }, (_, i) =>
    (2015 + i).toString()
  );

  const [newRow, setNewRow] = useState<Profile>({
    id: "",
    url: "",
    firstName: "",
    lastName: "",
    emailId: "",
    mobileNo: "",
    yearOfPassed: "",
    branch: "",
    graduation: "",
    isActive: true,
    resume: "",
    referenceName: "",
    referenceMobileNo: "",
  });

  useEffect(() => {
    let startOfDay;
    let endOfDay;
    // Ensure currentDateValue is a valid date string
    // if (!currentDateValue) {
    //   console.error("currentDateValue is empty or undefined");
    //   return; // Exit if currentDateValue is invalid
    // }
    if (currentDateValue && currentDateValue!=null) {
      // Create a Date object from currentDateValue
      const selectedDate = new Date(currentDateValue);

      // Check if the Date object is valid
      if (isNaN(selectedDate?.getTime())) {
        console.error("Invalid date value:", currentDateValue);
        return; // Exit if the date is invalid
      }

      // Set start and end of the day
      startOfDay = new Date(selectedDate); // Create a new Date object for startOfDay
      startOfDay.setHours(0, 0, 0, 0); // Start of the day

      endOfDay = new Date(selectedDate); // Create a new Date object for endOfDay
      endOfDay.setHours(23, 59, 59, 999); // End of the day
    }
    let q;
    if (!isActive && currentDateValue) {
      // Prepare the Firestore query
      q = query(
        collection(db, "CandidateProfiles"),
        where("createdBy", "==", userLoggedIn?.emailId),
        where("createdDate", ">=", Timestamp.fromDate(startOfDay)), // Compare with start of the day
        where("createdDate", "<=", Timestamp.fromDate(endOfDay)) // Compare with end of the day
      );
    } else {
      // Prepare the Firestore query
      q = query(
        collection(db, "CandidateProfiles"),
        where("createdBy", "==", userLoggedIn?.emailId)
      );
    }

    // Set up the snapshot listener
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        setLoader(true); // Start loading before processing
        const result = querySnapshot.docs.map((doc) => {
          const data = doc.data(); // Get the document data
          return {
            ...data,
            createdDate: data.createdDate
              ? data.createdDate.seconds
                ? new Date(data.createdDate.seconds * 1000)
                : data.createdDate.toDate()
              : null, // Convert Firestore timestamp or Unix timestamp to Date
          };
        });
        setProfiles(result as Profile[]); // Update state with profiles
        setTempProfiles(result as Profile[]); // Update temporary profiles
        handleActiveIsActive({ target: { checked: isActive } });
        setLoader(false); // Stop loading after processing
      },
      (error) => {
        addToast(error.message, "error"); // Handle errors
        console.log(error.message);
        setLoader(false); // Stop loading on error
      }
    );

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      unsubscribe();
    };
  }, [currentDateValue, userLoggedIn?.emailId,isActive]); // Add dependencies to the effect

  const handleInputChange = async (
    field: keyof Profile,
    value: any,
    profileId?: string
  ) => {
    if (profileId) {
      const updatedProfile = {
        ...profiles.find((p) => p.id === profileId),
        [field]: value,
      };

      setProfiles((prev) =>
        prev.map((p) => (p.id === profileId ? updatedProfile : p))
      );

      // Set a new timeout for updating the profile
      debounceTimeout.current = setTimeout(async () => {
        await updateCandidateProfile(profileId, updatedProfile);
      }, 5000); // 10 seconds debounce time
    } else {
      // Update the new row state immediately for instant feedback
      setNewRow((prev) => ({ ...prev, [field]: value }));
    }
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    profileId?: string,
    index?: number
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setLoader(true);

      const profileData = profileId
        ? profiles.find((p) => p.id === profileId)
        : newRow;

      if (!profileData) {
        throw new Error("Profile not found");
      }

      if (profileId) {
        const storageRef = ref(storage, `files/${file.name}`);
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);

        await addDoc(collection(db, "files"), {
          name: file.name,
          url,
        });
        const updatedProfile = {
          ...profileData,
          resume: file.name,
          url,
        };

        if (profileId) {
          await updateCandidateProfile(profileId, updatedProfile);
          setProfiles((prev) =>
            prev.map((p) => (p.id === profileId ? updatedProfile : p))
          );
        } else {
          setNewRow(updatedProfile);
        }

        addToast("File uploaded successfully", "success");
      } else {
        const updatedProfile = {
          ...profileData,
          resumeFile: file,
          resume: file.name,
        };
        setNewRow(updatedProfile);
      }
    } catch (error) {
      addToast(error.message || "Error uploading file", "error");
    } finally {
      setLoader(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const downloadFile = async (fileUrl, name) => {
    setLoader(true);
    if (!fileUrl || fileUrl == "") {
      setLoader(false);
      addToast("File Not Exists", "error");
      return;
    }
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        setLoader(false);
        addToast("Network response was not ok", "error");
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link: any = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name); // Provide a name for the file
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const removeFile = async (profileId?: string) => {
    const profile = profileId
      ? profiles.find((p) => p.id === profileId)
      : newRow;

    if (!profile?.resume) return; // Early exit if there is no resume to remove

    try {
      setLoader(true);
      const fileRef = ref(storage, `files/${profile.resume}`);
      await deleteObject(fileRef);

      const q = query(
        collection(db, "files"),
        where("name", "==", profile.resume)
      );
      const querySnapshot = await getDocs(q);

      for (const doc of querySnapshot.docs) {
        await deleteDoc(doc.ref);
      }

      addToast("File removed successfully", "success");
    } catch (error) {
      addToast(extractString(error.message) || "Error removing file", "error");
    } finally {
      setLoader(false);
    }

    // Update the profile after deletion attempt, regardless of success/failure
    const updatedProfile = {
      ...profile,
      resume: "",
      url: "",
    };

    if (profileId) {
      await updateCandidateProfile(profileId, updatedProfile);
      setProfiles((prev) =>
        prev.map((p) => (p.id === profileId ? updatedProfile : p))
      );
    } else {
      setNewRow(updatedProfile);
    }
  };

  const extractString = (input) => {
    const start = input.indexOf("'") - 1; // Find index after colon
    const end = input.lastIndexOf("."); // Find index of period

    // Validate indices
    if (start === -1 || end === -1 || start >= end) {
      return ""; // Return empty string if indices are invalid
    }

    return input.substring(start, end).trim(); // Extract and trim whitespace
  };

  const updateCandidateProfile = async (
    profileId: string,
    data: Partial<Profile>
  ) => {
    try {
      data["updatedBy"] = userLoggedIn?.emailId;
      const docRef = doc(db, "CandidateProfiles", profileId);
      await updateDoc(docRef, data);
    } catch (error) {
      addToast(error.message || "Error updating profile", "error");
    }
  };

  const addNewRow = async () => {
    if (!validateNewRow()) {
      addToast("Please fill all required fields", "warning");
      return;
    }

    try {
      setLoader(true);
      let newRowUpdated = {
        ...newRow,
      };
      if (newRowUpdated?.resumeFile) {
        const storageRef = ref(storage, `files/${newRowUpdated.resume}`);
        await uploadBytes(storageRef, newRowUpdated.resumeFile);
        const url = await getDownloadURL(storageRef);

        await addDoc(collection(db, "files"), {
          name: newRowUpdated.resume,
          url,
        });
        newRowUpdated = {
          ...newRowUpdated,
          resume: newRowUpdated.resume,
          url,
        };
      }

      const newProfileRef = doc(collection(db, "CandidateProfiles"));
      delete newRow.resumeFile;
      const profileData = {
        ...newRowUpdated,
        id: newProfileRef.id,
        createdBy: userLoggedIn?.emailId,
        createdDate: new Date(currentDateValue),
      };
      delete profileData.resumeFile;

      await setDoc(newProfileRef, profileData);
      // setProfiles((prev) => [...prev, profileData]);
      resetNewRow();
      addToast("Profile added successfully", "success");
    } catch (error) {
      addToast(error.message || "Error adding profile", "error");
    } finally {
      setLoader(false);
    }
  };

  const validateNewRow = () => {
    return (
      newRow.firstName &&
      newRow.lastName &&
      newRow.emailId &&
      newRow.mobileNo &&
      newRow.yearOfPassed &&
      newRow.graduation
    );
  };

  const resetNewRow = () => {
    setNewRow({
      id: "",
      url: "",
      firstName: "",
      lastName: "",
      emailId: "",
      mobileNo: "",
      yearOfPassed: "",
      branch: "",
      graduation: "",
      isActive: true,
      referenceName: "",
      referenceMobileNo: "",
      resume: "",
    });
  };

  const handleDeleteProfile = async (profileId: string) => {
    try {
      setLoader(true);
      const profile = profiles.find((p) => p.id === profileId);

      if (profile?.resume) {
        await removeFile(profileId);
      }

      await deleteDoc(doc(db, "CandidateProfiles", profileId));
      setProfiles((prev) => prev.filter((p) => p.id !== profileId));
      addToast("Profile deleted successfully", "success");
    } catch (error) {
      addToast(error.message || "Error deleting profile", "error");
    } finally {
      setLoader(false);
    }
  };

  const renderFileUpload = (profile?: Profile) => {
    const hasFile = profile ? profile.resume : newRow.resume;

    return (
      <div className="flex items-center gap-2">
        {hasFile ? (
          <div className="flex items-center gap-2 px-3 py-1.5 bg-blue-50 text-[#0071bd] rounded-lg w-full">
            {/* Download Icon */}
            <button
              onClick={() =>
                downloadFile(profile ? profile.url : "", profile.resume)
              } // Adjust based on available URL
              className="text-[#0071bd] hover:text-blue-700 p-0.5 rounded-full hover:bg-blue-100"
            >
              <Download className="w-4 h-4" />{" "}
              {/* Assuming 'Download' is an appropriate icon */}
            </button>

            <span className="flex-1 truncate text-sm">
              {profile ? profile.resume : newRow.resume}
            </span>

            <button
              onClick={() => removeFile(profile?.id)}
              className="text-red-600 hover:text-red-900 p-0.5 rounded-full hover:bg-red-50"
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        ) : (
          <>
            <button
              onClick={() => fileInputRef.current?.click()}
              className="flex items-center gap-2 px-3 py-1.5 text-sm text-[#0071bd] border border-[#0071bd] rounded-lg hover:bg-[#0071bd] hover:text-white transition-colors w-full"
            >
              <Upload className="w-4 h-4" />
              <span>Upload Resume</span>
            </button>
            <input
              type="file"
              ref={fileInputRef}
              onChange={(e) => handleFileUpload(e, profile?.id)}
              className="hidden"
              accept=".pdf,.doc,.docx"
            />
          </>
        )}
      </div>
    );
  };

  const renderEditableCell = (
    profile: Profile,
    field: keyof Profile,
    index: number
  ) => {
    if (field === "resume") {
      return renderFileUpload(profile);
    }

    if (field === "yearOfPassed") {
      return (
        <select
          value={profile.yearOfPassed}
          onChange={(e) => handleInputChange(field, e.target.value, profile.id)}
          className=" min-w-[1rem] px-2 py-1 border-0 focus:ring-2 focus:ring-[#0071bd] rounded bg-white"
        >
          <option value="">Select Year</option>
          {yearOptions.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      );
    }

    if (field === "graduation") {
      return (
        <select
          value={profile.graduation}
          onChange={(e) => handleInputChange(field, e.target.value, profile.id)}
          className="w-full px-2 py-1 border-0 focus:ring-2 focus:ring-[#0071bd] rounded bg-white"
        >
          <option value="">Select Graduation</option>
          {graduationOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    }

    if (field === "branch") {
      return (
        <select
          value={profile.branch}
          onChange={(e) => handleInputChange(field, e.target.value, profile.id)}
          className="max-w-[6rem] px-2 py-1 border-0 focus:ring-2 focus:ring-[#0071bd] rounded bg-white"
        >
          <option value="">Select Branch</option>
          {branchOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    }

    if (field === "isActive") {
      return (
        <div className="flex flex-col space-x-4 mt-1">
          <div className="relative inline-flex items-center">
            <label className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={profile.isActive}
                onChange={(e) =>
                  handleInputChange(field, e.target.checked, profile.id)
                }
                className="sr-only"
              />
              <div className="w-14 h-8 bg-gray-300 rounded-full shadow-inner"></div>
              <div
                className={`absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-200 ease-in-out ${
                  profile.isActive
                    ? "translate-x-full bg-[#0071bd]"
                    : "bg-gray-500"
                }`}
              ></div>
            </label>
          </div>
        </div>
      );
    }

    return (
      <input
        type={
          field === "emailId"
            ? "email"
            : field === "mobileNo" || field === "referenceMobileNo"
            ? "tel"
            : field === "referenceName"
            ? "text"
            : "text" // Default fallback
        }
        value={profile[field] as string}
        onChange={(e) => handleInputChange(field, e.target.value, profile.id)}
        className="min-w-[3rem] px-2 py-1 border-0 focus:ring-2 focus:ring-[#0071bd] rounded"
        placeholder={
          field.charAt(0).toUpperCase() +
          field.slice(1).replace(/([A-Z])/g, " $1")
        }
      />
    );
  };

  const handleFilterActive = (isActiveFilter: boolean) => {
    setIsActive(isActiveFilter);
    if (tempProfiles?.length > 0) {
      const filteredProfiles = tempProfiles.filter(
        (p) => p.isActive === isActiveFilter
      );
      setProfiles(filteredProfiles);
    }
  };

  const deleteRow = (profile: any) => {
    setIsConfirmDeleteOpen(true);
    setSelectedProfileToDelete(profile);

    //setProfiles((prev) => prev.filter((p) => p.id !== profile.id));
  };

  const downloadAsExcel1 = () => {
    const getColumnWidths = (data: string[][]) => {
      const widths: number[] = [];
      for (let i = 0; i < data[0].length; i++) {
        const columnValues = data.map((row) => row[i].length);
        widths[i] = Math.max(...columnValues);
      }
      return widths;
    };

    const headers = [
      "First Name",
      "Last Name",
      "Email",
      "Mobile",
      "Year Passed",
      "Branch",
      "Is Active",
      "Graduation",
      "Resume",
      "referenceName",
      "referenceMobileNO",
    ];
    const rows = profiles.map((p) => [
      p.firstName,
      p.lastName,
      p.emailId,
      p.mobileNo,
      p.yearOfPassed,
      p.branch,
      p.isActive ? "Yes" : "No", // Convert boolean to string
      p.graduation,
      p.resume,
      p.referenceName ?? "",
      p.referenceMobileNo ?? "",
    ]);

    const allData = [headers, ...rows];
    const columnWidths = getColumnWidths(allData);

    let csvContent =
      headers
        .map((header, index) => {
          return header.padEnd(columnWidths[index]);
        })
        .join(",") + "\n";

    csvContent += rows
      .map((row) =>
        row.map((cell, index) => cell.padEnd(columnWidths[index])).join(",")
      )
      .join("\n");

    const excelContent = `  
      <html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel">  
      <head>  
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">  
        <style>  
          th { font-weight: bold; background-color: #f3f4f6; }  
          td, th { min-width: 100px; padding: 5px; }  
          tr,th,td{ border:1px solid gray;}  
        </style>  
      </head>  
      <body>  
        <table>  
          <thead>  
            <tr>  
              ${headers.map((header) => `<th>${header}</th>`).join("")}  
            </tr>  
          </thead>  
          <tbody>  
            ${rows
              .map(
                (row) => `  
              <tr>  
                ${row.map((cell) => `<td>${cell}</td>`).join("")}  
              </tr>  
            `
              )
              .join("")}  
          </tbody>  
        </table>  
      </body>  
      </html>  
    `;

    const blob = new Blob([excelContent], { type: "application/vnd.ms-excel" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    const timestamp = new Date(currentDateValue).toISOString().split("T")[0];
    link.download = `${timestamp}_profiles.xlsx`;
    link.click();
  };

  const downloadAsExcel = () => {
    // Define styles
    const headerStyle = {
      font: {
        bold: true,
        color: { rgb: "00000000" }, // Black color for text
      },
      fill: {
        fgColor: { rgb: "FFf3f4f6" }, // Background color with full opacity
      },
      border: {
        top: { style: "thin", color: { rgb: "FF0071BD" } },
        bottom: { style: "thin", color: { rgb: "FF0071BD" } },
        left: { style: "thin", color: { rgb: "FF0071BD" } },
        right: { style: "thin", color: { rgb: "FF0071BD" } },
      },
    };

    const cellStyle = {
      border: {
        top: { style: "thin", color: { rgb: "FF0071BD" } },
        bottom: { style: "thin", color: { rgb: "FF0071BD" } },
        left: { style: "thin", color: { rgb: "FF0071BD" } },
        right: { style: "thin", color: { rgb: "FF0071BD" } },
      },
    };

    // Define the headers for the Excel file
    const headers = [
      "S.No",
      "First Name",
      "Last Name",
      "Email",
      "Mobile",
      "Year Passed",
      "Branch",
      "Graduation",
      "Resume",
      "Reference Name",
      "Reference Mobile No",
    ];

    // Prepare the rows data
    const rows = profiles.map((p, i) => [
      i + 1,
      p.firstName,
      p.lastName,
      p.emailId,
      p.mobileNo,
      p.yearOfPassed,
      p.branch,
      p.graduation,
      p.resume,
      p.referenceName ?? "",
      p.referenceMobileNo ?? "",
    ]);

    // Prepare data for worksheet
    const worksheetData = [headers, ...rows];

    // Create a worksheet from the data
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Apply styles to the header
    for (let i = 0; i < headers.length; i++) {
      const cellRef = XLSX.utils.encode_cell({ c: i, r: 0 }); // Header row is at index 0
      if (!worksheet[cellRef]) worksheet[cellRef] = {}; // Initialize cell if not present
      worksheet[cellRef].s = headerStyle; // Apply header style
    }

    // Apply styles to the body cells
    for (let r = 1; r < worksheetData.length; r++) {
      // Start from 1 to skip header row
      for (let c = 0; c < worksheetData[r].length; c++) {
        const cellRef = XLSX.utils.encode_cell({ c, r }); // Get cell reference
        if (!worksheet[cellRef]) worksheet[cellRef] = {}; // Initialize cell if not present
        worksheet[cellRef].s = cellStyle; // Apply cell style
      }
    }

    // Set minimum widths for columns
    worksheet["!cols"] = [
      { wch: 4 },
      { wch: 8 }, // Width for "First Name"
      { wch: 8 }, // Width for "Last Name"
      { wch: 30 }, // Width for "Email"
      { wch: 12 }, // Width for "Mobile"
      { wch: 7 }, // Width for "Year Passed"
      { wch: 7 }, // Width for "Branch"
      { wch: 10 }, // Width for "Graduation"
      { wch: 8 }, // Width for "Resume"
      { wch: 15 }, // Width for "Reference Name"
      { wch: 10 }, // Width for "Reference Mobile No"
    ];

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Profiles");

    // Generate the Excel file and prompt the user to download it
    const timestamp = new Date().toISOString().split("T")[0]; // Format date as YYYY-MM-DD
    XLSX.writeFile(workbook, `${timestamp}_profiles.xlsx`);
  };

  const handleActiveIsActive = (e: any, value?: boolean) => {
    setIsActive(e?.target?.checked ?? value);
    if (isActive) {
     // setCurrentDateValue(null);
    }
    // if (profiles?.length > 0 || tempProfiles?.length > 0) {
    //   const profilesResult = JSON.parse(
    //     JSON.stringify(
    //       tempProfiles?.filter((p) => p.isActive == e.target.checked)
    //     )
    //   );
    //   setProfiles(profilesResult);
    // };
  };

  const handleDeleteConfirm = async () => {
    setIsConfirmDeleteOpen(false);
    handleDeleteProfile(selectedProfileToDelete?.id);
  };
  const handleCancel = async () => {
    setIsConfirmDeleteOpen(false);
  };

  const handleDateChange = (date) => {
    // Implement what happens with the selected date
    console.log("Selected date:", date);
    // today.toISOString().split("T")[0]
    setCurrentDateValue(new Date(date).toISOString().split("T")[0]);
  };

  return (
    <>
      {isConfirmDeleteOpen && (
        <ConfirmationPopup
          message="Are you sure you want to delete the data?"
          onConfirm={handleDeleteConfirm}
          onCancel={handleCancel}
        />
      )}
      <div
        className="overflow-x-auto bg-white rounded-lg shadow"
        style={{ marginBottom: "5rem" }}
      >
        <div className="p-4 flex justify-between items-center border-b vertical-align-middle">
          <div className="flex items-center justify-between mb-4 w-full">
            <h2 className="text-lg sm:text-xl font-semibold text-gray-500 mr-4 flex items-center">
              {isActive ? (
                <span className="hidden md:inline">All Profiles</span>
              ) : (
                <span className="hidden md:inline">Selected Profiles</span>
              )}
              <span className="block md:hidden">
                {isActive ? "All Profiles" : "Selected Profiles"}
              </span>

              <label className="cursor-pointer relative inline-flex items-center ml-4">
                <input
                  type="checkbox"
                  name="isActive"
                  checked={isActive}
                  onChange={(e) => handleActiveIsActive(e)}
                  className="sr-only"
                />
                <div className="w-14 h-8 bg-gray-300 rounded-full shadow-inner"></div>
                <div
                  className={`absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-200 ease-in-out ${
                    isActive ? "translate-x-full bg-[#0071bd]" : "bg-gray-500"
                  }`}
                ></div>
              </label>
            </h2>

            <button
              onClick={downloadAsExcel}
              disabled={profiles?.length === 0}
              className="flex items-center justify-center p-2 border border-green-500 bg-green-100 rounded-lg shadow-md transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg hover:bg-green-200 active:scale-95"
            >
              <Download className="w-4 h-4 mr-2 transition-transform duration-300 ease-in-out" />
              <ExcelIcon className="h-6 w-6 transition-transform duration-300 ease-in-out hidden sm:flex" />
              <span className="mx-2" />
            </button>
          </div>
        </div>
        <div className="flex items-center justify-start mb-4 m-2">
          {!isActive && (
            <>
              <label
                htmlFor="dateInput"
                className="block text-md font-medium text-gray-700 mb-1 mr-4 ml-2"
              >
                Select Date
              </label>
              <input
                type="date"
                id="dateInput"
                value={currentDateValue}
                onChange={(e) => handleDateChange(e.target.value)}
                className="max-w-[200px] p-2 ml-4 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out"
              />
            </>
          )}
        </div>

        <div className="overflow-x-auto">
          <table className=" bg-white rounded-lg shadow-md">
            <thead className="bg-gray-50">
              <tr>
                {[
                  "First Name",
                  "Last Name",
                  "Email ID",
                  "Mobile No",
                  "Year of Passed",
                  "Branch",
                  "Is Active",
                  "Graduation",
                  "Resume",
                  "Reference Name",
                  "Reference Mobile No",
                  "Actions",
                ].map((header, i) => (
                  <th
                    key={i + 1}
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {profiles.map((profile, i) => (
                <tr className="hover:bg-gray-50" key={profile.id}>
                  <td className="px-4 py-2 min-w-[4rem]">
                    {renderEditableCell(profile, "firstName", i)}
                  </td>
                  <td className="px-4 py-2">
                    {renderEditableCell(profile, "lastName", i)}
                  </td>
                  <td className="px-4 py-2">
                    {renderEditableCell(profile, "emailId", i)}
                  </td>
                  <td className="px-4 py-2">
                    {renderEditableCell(profile, "mobileNo", i)}
                  </td>
                  <td className="px-4 py-2">
                    {renderEditableCell(profile, "yearOfPassed", i)}
                  </td>
                  <td className="px-4 py-2">
                    {renderEditableCell(profile, "branch", i)}
                  </td>
                  <td className="px-4 py-2">
                    {renderEditableCell(profile, "isActive", i)}
                  </td>
                  <td className="px-4 py-2">
                    {renderEditableCell(profile, "graduation", i)}
                  </td>

                  <td className="px-4 py-2">
                    {renderEditableCell(profile, "resume", i)}
                  </td>
                  <td className="px-4 py-2">
                    {renderEditableCell(profile, "referenceName", i)}
                  </td>
                  <td className="px-4 py-2">
                    {renderEditableCell(profile, "referenceMobileNo", i)}
                  </td>
                  <td className="px-4 py-2">
                    <button
                      onClick={() => deleteRow(profile)}
                      className="text-red-600 hover:text-red-900 ml-2"
                    >
                      <Trash className="w-5 h-5" />
                    </button>
                  </td>
                </tr>
              ))}
              <tr className="hover:bg-gray-50">
                <td className="px-4 py-2 min-w-[13rem]">
                  <input
                    type="text"
                    value={newRow.firstName}
                    onChange={(e) =>
                      handleInputChange("firstName", e.target.value)
                    }
                    className="w-full px-2 py-1 min-w-full  border rounded focus:ring-2 focus:ring-[#0071bd]"
                    placeholder="First Name"
                  />
                </td>
                <td className="px-4 py-2 min-w-[13rem]">
                  <input
                    type="text"
                    value={newRow.lastName}
                    onChange={(e) =>
                      handleInputChange("lastName", e.target.value)
                    }
                    className="w-full px-2 py-1 border rounded focus:ring-2 focus:ring-[#0071bd]"
                    placeholder="Last Name"
                  />
                </td>
                <td className="px-4 py-2 min-w-[13rem]">
                  <input
                    type="email"
                    value={newRow.emailId}
                    onChange={(e) =>
                      handleInputChange("emailId", e.target.value)
                    }
                    className="w-full px-2 py-1 border rounded focus:ring-2 focus:ring-[#0071bd]"
                    placeholder="Email"
                  />
                </td>
                <td className="px-4 py-2 min-w-[11rem]">
                  <input
                    type="tel"
                    value={newRow.mobileNo}
                    onChange={(e) =>
                      handleInputChange("mobileNo", e.target.value)
                    }
                    className="w-full px-2 py-1 border rounded focus:ring-2 focus:ring-[#0071bd]"
                    placeholder="Mobile"
                  />
                </td>
                <td className="px-4 py-2 min-w-[8rem]">
                  <select
                    value={newRow.yearOfPassed}
                    onChange={(e) =>
                      handleInputChange("yearOfPassed", e.target.value)
                    }
                    className="w-full px-2 py-1 border rounded focus:ring-2 focus:ring-[#0071bd]"
                  >
                    <option value="">Select</option>
                    {yearOptions.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="px-4 py-2 min-w-[8rem]">
                  <select
                    value={newRow.branch}
                    onChange={(e) =>
                      handleInputChange("branch", e.target.value)
                    }
                    className="w-full px-2 py-1 border rounded focus:ring-2 focus:ring-[#0071bd]"
                  >
                    <option value="">Select Branch</option>
                    {branchOptions.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="px-4 py-2 min-w-[8rem]">
                  <label className="flex items-center cursor-pointer relative">
                    <input
                      type="checkbox"
                      value={newRow.isActive}
                      onChange={(e) =>
                        handleInputChange("isActive", e.target.checked)
                      }
                      checked={newRow.isActive}
                      className="sr-only"
                    />
                    <div className="w-14 h-8 bg-gray-300 rounded-full shadow-inner"></div>
                    <div
                      className={`absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-200 ease-in-out ${
                        newRow.isActive
                          ? "translate-x-full bg-[#0071bd]"
                          : "bg-gray-500"
                      }`}
                    ></div>
                  </label>
                </td>
                <td className="px-4 py-2 min-w-[9rem]">
                  <select
                    value={newRow.graduation}
                    onChange={(e) =>
                      handleInputChange("graduation", e.target.value)
                    }
                    className="w-full px-2 py-1 border rounded focus:ring-2 focus:ring-[#0071bd]"
                  >
                    <option value="">Select Graduation</option>
                    {graduationOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="px-4 py-2 min-w-12rem]">
                  <div className="flex items-center gap-2">
                    {newRow.resume ? (
                      <div className="flex items-center gap-2 px-3 py-1.5 bg-blue-50 text-[#0071bd] rounded-lg w-full">
                        {/* Download Icon */}
                        <button
                          onClick={() =>
                            downloadFile(newRow.url, newRow.resume)
                          } // Function to download the file
                          className="text-[#0071bd] hover:text-blue-700 p-0.5 rounded-full hover:bg-blue-100"
                        >
                          <Download className="w-4 h-4" />{" "}
                          {/* Assuming 'Download' is an appropriate icon */}
                        </button>

                        <span className="flex-1 truncate text-sm">
                          {newRow.resume}
                        </span>

                        <button
                          onClick={() => removeFile()}
                          className="text-red-600 hover:text-red-900 p-0.5 rounded-full hover:bg-red-50"
                        >
                          <X className="w-4 h-4" />
                        </button>
                      </div>
                    ) : (
                      <button
                        onClick={() => fileInputRef.current?.click()}
                        className="flex items-center gap-2 px-3 py-1.5 text-sm text-[#0071bd] border border-[#0071bd] rounded-lg hover:bg-[#0071bd] hover:text-white transition-colors w-full"
                      >
                        <Upload className="w-4 h-4" />
                        <span>Resume</span>
                      </button>
                    )}
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={(e) => handleFileUpload(e)}
                      className="hidden"
                      accept=".pdf,.doc,.docx"
                    />
                  </div>
                </td>
                <td className="px-4 py-2 min-w-[13rem]">
                  <input
                    type="text"
                    value={newRow.referenceName}
                    onChange={(e) =>
                      handleInputChange("referenceName", e.target.value)
                    }
                    className="w-full px-2 py-1 border rounded focus:ring-2 focus:ring-[#0071bd]"
                    placeholder="Reference Name"
                  />
                </td>
                <td className="px-4 py-2 min-w-[11rem]">
                  <input
                    type="tel"
                    value={newRow.referenceMobileNo}
                    onChange={(e) =>
                      handleInputChange("referenceMobileNo", e.target.value)
                    }
                    className="w-full px-2 py-1 border rounded focus:ring-2 focus:ring-[#0071bd]"
                    placeholder="Reference Mobile No"
                  />
                </td>

                <td className="px-4 py-2">
                  <button
                    onClick={addNewRow}
                    className="p-2 text-[#0071bd] border border-[#0071bd] rounded-full hover:bg-[#0071bd] hover:text-white transition-colors"
                  >
                    <Plus className="w-5 h-5" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ProfileTable;
