import React from "react";  // Update this import to match your types definition  
import BGVCard from "../BGV/BGVCard"; // Assuming you have a BGVCard similar to CandidateCard  

interface BGVGridProps {  
  bgvProfiles: any[]; // Update type according to your types  
  onEdit: (bgv: any) => any;  
  onDelete: (id: string) => void;  
  isSuperAdmin: boolean;  
  isBGVTeam: boolean; // Assuming you want this as a prop  
}  

const BGVGrid: React.FC<BGVGridProps> = ({  
  bgvProfiles,  
  onEdit,  
  onDelete,  
  isSuperAdmin,  
  isBGVTeam,  
}) => {  
  return (  
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">  
      {bgvProfiles.map((bgv: any) => (  
        <BGVCard  
          key={bgv.id}  
          bgvProfile={bgv}  
          onEdit={() => onEdit(bgv)}  
          onDelete={() => onDelete(bgv.id)} // Pass the ID for deletion  
          isSuperAdmin={ isSuperAdmin}  
          isBGVTeam={isBGVTeam} 
        />  
      ))}  
    </div>  
  );  
};  

export default BGVGrid;  