import React, { useState } from "react";
import jsPDF from "jspdf";
import moment from "moment";
import image from "../static/images/Logo_Cropped.png"; // ByteSplash logo import
import sign from "../static/images/GSR_Sign.png";

import {loadImageAsBlob,loadBlobAsImage,compressImage} from '../components/utils/fileUtils';

const ExperienceLetter = ({ setLoader }) => {
  // State Variables
  const [employeeName, setEmployeeName] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [designation, setDesignation] = useState("");
  const [joiningDate, setJoiningDate] = useState(moment().format("YYYY-MM-DD"));
  const [lastWorkingDay, setLastWorkingDay] = useState(
    moment().format("YYYY-MM-DD")
  );

  const companyName = "BYTE SPLASH TRAINING AND PLACEMENT CENTER";
  const companyAddress =
    "4th Floor, No 92/9, PR Layout, Marathahalli, Bengaluru, Karnataka - 560037";

 
//   // Function to load the static image and convert it to a Blob
//   const loadImageAsBlob = (imageSrc) => {
//     return new Promise((resolve, reject) => {
//       const img = new Image();
//       img.crossOrigin = "Anonymous"; // Handle cross-origin images if needed
//       img.src = imageSrc;

//       img.onload = () => {
//         const canvas = document.createElement("canvas");
//         canvas.width = img.width;
//         canvas.height = img.height;
//         const ctx = canvas.getContext("2d");
//         ctx.drawImage(img, 0, 0);

//         // Convert canvas to Blob
//         canvas.toBlob(
//           (blob) => {
//             if (blob) {
//               resolve(blob);
//             } else {
//               reject(new Error("Failed to convert image to Blob."));
//             }
//           },
//           "image/png",
//           0.8
//         ); // Specify the desired quality (0.8 here)
//       };

//       img.onerror = () => {
//         reject(new Error("Failed to load image."));
//       };
//     });
//   };

//   const loadBlobAsImage = (blob) => {
//     return new Promise((resolve, reject) => {
//       // Create a FileReader to read the Blob as a data URL
//       const reader = new FileReader();

//       reader.onload = () => {
//         const img = new Image();
//         img.src = reader.result; // Set image source from FileReader results

//         img.onload = () => {
//           resolve(img); // Resolve the promise with the Image object
//         };

//         img.onerror = () => {
//           reject(new Error("Failed to load image from Blob."));
//         };
//       };

//       reader.onerror = () => {
//         reject(new Error("Failed to read Blob."));
//       };

//       // Read the Blob as a data URL
//       reader.readAsDataURL(blob);
//     });
//   };

  const generatePDF = async () => {
    setLoader(true);
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    let startY = 10;
    const lineHeight = 4.5;

    if (image) {
      const imageBlob = await loadImageAsBlob(image);
      const compressedImage = await compressImage(imageBlob, {
        quality: 0.8,
        maxWidth: 800,
      });
      //   const compressedImage = await compressImage(image, 54); // Resize to max width of the logo
      const bolbTOIMage = await loadBlobAsImage(compressedImage);
      if (bolbTOIMage) {
        doc.addImage(bolbTOIMage, "PNG", 15, startY, 54, 16);
      }
    }

    doc.setFont("helvetica", "normal");
    doc.setFontSize(7);
    doc.text(`GSTIN : 29AAZFG5482H1ZJ`, 17, startY + 17);
    doc.setFontSize(10);
    const companyNameX = 75;
    doc.setFont("helvetica", "bold");
    doc.text(companyName, companyNameX, startY + 7);

    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    doc.text(companyAddress, companyNameX, startY + 11);

    startY += 30;

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    let currentY = startY;
    doc.text(`Name : ${employeeName}`, 15, currentY);
    currentY += 7;
    doc.text(`Emp No : ${employeeId}`, 15, currentY);
    currentY += 7;
    doc.text(`Designation : ${designation}`, 15, currentY);
    currentY += 7;
    doc.text(
      `Date of Joining : ${moment(joiningDate).format("DD MMMM YYYY")}`,
      15,
      currentY
    );
    currentY += 7;
    doc.text(
      `Last Working Day : ${moment(lastWorkingDay).format("DD MMMM YYYY")}`,
      15,
      currentY
    );

    startY = currentY + 15;
    const pageWidth = doc.internal.pageSize.getWidth();
    const experienceCertificateText = "EXPERIENCE CERTIFICATE";
    const textWidth = doc.getTextWidth(experienceCertificateText);
    const startX = (pageWidth - textWidth) / 2;

    // Title
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text(experienceCertificateText, startX, startY);
    startY += 15;

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    const joiningDateFormat = moment(joiningDate).format("DD MMMM YYYY");
    const lastWorkingDayFormat = moment(lastWorkingDay).format("DD MMMM YYYY");

    const paragraph1Initial = "This is to certify that Mr. ";
    const paragraph1End = `has been associated with our organization as ${designation} for the period from ${joiningDateFormat} to ${lastWorkingDayFormat}.`;
    const paragraph2Initial = "We found Mr. ";
    const paragraph2End = ` is effective in discharging responsibilities assigned to him. We have found him to be a self-starter who is motivated, duty-bound, and a highly committed team player with strong conceptual knowledge.`;
    const paragraph3 =
      "We at Byte Splash wish him all success in his future endeavors.";

    const maxWidth = 180;
    const leftMargin = 15;
    const lineHeightIncrement = 10;

    const drawParagraphWithBold = (
      doc,
      x,
      y,
      text,
      boldText,
      extraText = ""
    ) => {
      let currentX = x;
      let currentY = y;
      const lineHeight = 5;

      const writeText = (text, isBold) => {
        doc.setFont("helvetica", isBold ? "bold" : "normal");
        const words = text?.split(" ");

        for (let i = 0; i < words?.length; i++) {
          const word = words[i];
          const wordWidth = doc.getTextWidth(word + " ");

          if (currentX + wordWidth > x + maxWidth) {
            currentX = x;
            currentY += lineHeight;
          }

          doc.text(word + " ", currentX, currentY);
          currentX += wordWidth;
        }
      };

      let allText = text.split(boldText);
      let left = allText[0];
      let right = allText[1];
      doc.setFont("helvetica", "normal");
      writeText(left, false);
      doc.setFont("helvetica", "bold");
      writeText(boldText, true);
      doc.setFont("helvetica", "normal");
      writeText(extraText ?? "", false);
      doc.setFont("helvetica", "normal");
      writeText(right, false);
      return currentY;
    };

    currentY = drawParagraphWithBold(
      doc,
      leftMargin,
      startY,
      `This is to certify that Mr.`,
      employeeName,
      paragraph1End
    );
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    currentY += lineHeightIncrement;
    doc.setFont("helvetica", "normal");
    const paragraph2_initial = `We found Mr. ` + employeeName + ``;
    const lines = doc.splitTextToSize(paragraph2_initial + paragraph2End, 180);
    doc.setFont("helvetica", "normal");
    doc.text(lines, leftMargin, currentY);

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    currentY += 7;

    doc.text(paragraph3, leftMargin, currentY + lineHeightIncrement);

    startY = currentY + 20;

    doc.text("Best Regards,", 15, startY);
    startY += 5;
    doc.text("From Byte Splash,", 15, startY);
    startY += 5;
    doc.setFont("helvetica", "bold");
    doc.text("Sagar Guvvala", 15, startY);
    doc.setFont("helvetica", "normal");

    if (sign) {
      const imageBlob = await loadImageAsBlob(sign);
      const compressedImage = await compressImage(imageBlob, {
        quality: 0.8,
        maxWidth: 800,
      });
      //   const compressedImage = await compressImage(image, 54); // Resize to max width of the logo
      const bolbTOIMage = await loadBlobAsImage(compressedImage);
      if (bolbTOIMage) {
        doc.addImage(bolbTOIMage, "PNG", 15, startY, 40, 14);
      }
    }

    startY += 10;
    doc.text("Founder and CEO", 15, startY + 10);

    // Save the PDF
    doc.save("experience_letter.pdf");
    setLoader(false);
  };

  return (
    <div className="container mx-auto p-4 bg-gray-100 text-gray-800 font-sans">
      <div className="mb-5">
        <img
          src={image}
          alt="Company Logo"
          className="max-w-xs max-h-20 mx-auto"
        />
      </div>
      <form className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Form Inputs */}
        <div className="flex flex-col mb-4">
          <label className="font-bold mb-1 text-sm">Employee Name</label>
          <input
            type="text"
            value={employeeName}
            onChange={(e) => setEmployeeName(e.target.value)}
            className="p-2 text-md border border-gray-300 rounded focus:border-blue-500 focus:outline-none"
            required
          />
        </div>

        <div className="flex flex-col mb-4">
          <label className="font-bold mb-1 text-sm">Employee ID</label>
          <input
            type="text"
            value={employeeId}
            onChange={(e) => setEmployeeId(e.target.value)}
            className="p-2 text-md border border-gray-300 rounded focus:border-blue-500 focus:outline-none"
            required
          />
        </div>

        <div className="flex flex-col mb-4">
          <label className="font-bold mb-1 text-sm">Designation</label>
          <input
            type="text"
            value={designation}
            onChange={(e) => setDesignation(e.target.value)}
            className="p-2 text-md border border-gray-300 rounded focus:border-blue-500 focus:outline-none"
            required
          />
        </div>

        <div className="flex flex-col mb-4">
          <label className="font-bold mb-1 text-sm">Joining Date</label>
          <input
            type="date"
            value={joiningDate}
            onChange={(e) => setJoiningDate(e.target.value)}
            className="p-2 text-md border border-gray-300 rounded focus:border-blue-500 focus:outline-none"
            required
          />
        </div>

        <div className="flex flex-col mb-4">
          <label className="font-bold mb-1 text-sm">Last Working Day</label>
          <input
            type="date"
            value={lastWorkingDay}
            onChange={(e) => setLastWorkingDay(e.target.value)}
            className="p-2 text-md border border-gray-300 rounded focus:border-blue-500 focus:outline-none"
            required
          />
        </div>

        {/* Generate PDF Button */}
        <button
          type="button"
          onClick={generatePDF}
          className="col-span-1 md:col-span-2 p-2 text-lg text-white bg-[#0071bd] rounded hover:bg-[#005f8a] transition duration-200 ease-in-out transform hover:scale-105"
        >
          Generate Experience Letter
        </button>
      </form>
    </div>
  );
};

export default ExperienceLetter;
