import { getAuth } from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { app } from "../firebase";
import { setSelectedEmail as setSelectedEmailSlice } from "../store/candidatesSlice";
import { setEventsValue, setFilteredEventsValue } from "../store/eventsSlice";
import EventModal from "./EventModal"; // Import the new modal component
import "./css/Schedules.css"; // Custom CSS file

// Custom event component
const CustomEvent = ({ event }) => {
  return (
    <div>
      {/* <strong>{event.title}</strong>   */}
      {/* <p>{event.companyName}</p>   */}
      {/* <p className="ml-3">{event.roundDetails.split(" ")[0]}</p> */}
    </div>
  );
};

const localizer = momentLocalizer(moment); // Set the localizer for the calendar

const Schedules = ({ setLoader, addToast, user }) => {
  const dispatch = useDispatch();
  const rawEventsData = useSelector((state) => state.events.events);
  const rawFilteredEventsData = useSelector(
    (state) => state.events.filteredEvents
  );
  const selectedEmailData = useSelector((state) => state.events.selectedEmail);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [resourcesData, setResourcesData] = useState([]);
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSlotEndTime, setSelectedSlotEndTime] = useState(null);
  const [selectedSlotStartTime, setSelectedSlotStartTime] = useState(null);
  const [candidates, setCandidates] = useState([]);

  const db = getFirestore(app);
  const auth = getAuth(app);

  // Function to get the start and end of the current week  
  const getCurrentWeekStartEnd = () => {
    const now = selectedDate ? selectedDate : new Date();
    const dayOfWeek = now.getDay(); // Sunday is 0, Monday is 1, etc.  
    const startOfWeek = new Date(now); // Copy the current date  
    startOfWeek.setDate(now.getDate() - dayOfWeek); // Set to start of week (Sunday)  
    startOfWeek.setHours(0, 0, 0, 0); // Set time to midnight  

    const endOfWeek = new Date(startOfWeek); // Copy start of week  
    endOfWeek.setDate(startOfWeek.getDate() + 6); // Set to end of week (Saturday)  
    endOfWeek.setHours(23, 59, 59, 999); // Set time to end of day  

    return { startOfWeek, endOfWeek };
  }

  const updateSelectedEmail = useCallback((email) => {
    setSelectedEmail(email);
    //setSelectedEmailData(email);
  }, [selectedEmailData]);

  // Deserialize events: Convert timestamps back to Date objects
  const eventsData = useMemo(() => {
    return (
      rawEventsData?.map((event) => ({
        ...event,
        start: event.start ? new Date(event.start) : null,
        end: event.end ? new Date(event.end) : null,
        date: event.date ? new Date(event.date) : null,
      })) || []
    );
  }, [rawEventsData]);

  const filteredEventsData = useMemo(() => {
    return (
      rawFilteredEventsData?.map((event) => ({
        ...event,
        start: event.start ? new Date(event.start) : null,
        end: event.end ? new Date(event.end) : null,
        date: event.date ? new Date(event.date) : null,
      })) || []
    );
  }, [rawFilteredEventsData]);

  useEffect(() => {
    // setLoader(true);
    if (user?.emailId == "mahi@bytesplash.in") {
      localStorage.clear();
      window.location.href = "/login"
    }
    const resourcesUnsubscribe = onSnapshot(
      collection(db, "BDResources"),
      (querySnapshot) => {
        setLoader(true); // Show loader while fetching data
        const result = querySnapshot.docs.map((doc) => doc.data());
        setResourcesData(result);
        setLoader(false); // Hide loader after fetching data
        // Set the initial selected email to the first email in the candidates list
        // setSelectedEmail(result[0].email);
        // dispatch(setSelectedEmailSlice("sagar.guvvala@bytesplash.in"));
      },
      (error) => {
        addToast(error.message, "error"); // Handle errors
        setLoader(false); // Hide loader in case of error
      }
    );

    const candidatesUnsubscribe = onSnapshot(
      collection(db, "BDProfiles"),
      (querySnapshot) => {
        setLoader(true); // Show loader while fetching data
        const candidatesData = querySnapshot.docs.map((doc) => doc.data());
        setCandidates(candidatesData); // Update candidates state
        setLoader(false); // Hide loader after fetching data
      },
      (error) => {
        addToast(error.message, "error"); // Handle errors
        setLoader(false); // Hide loader in case of error
      }
    );

    const eventsUnsubscribe = onSnapshot(
      collection(db, "BDSchedules"),

      (querySnapshot) => {

        const isMobile = window.innerWidth <= 768; // Adjust based on your mobile breakpoint  
        const currentDate = selectedDate;

        const eventList = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            start: data?.start?.seconds ? new Date(data.start.seconds * 1000) : null,
            end: data?.end?.seconds ? new Date(data.end.seconds * 1000) : null,
            date: data?.date?.seconds ? new Date(data.date.seconds * 1000) : null,
          };
        });

        // Filter events based on the current view  
        const filteredEventList = eventList.filter((event) => {
          const eventStart = event.start;
          if (isMobile) {
            // Mobile view: Show events that start today  
            return (
              eventStart &&
              eventStart.getFullYear() === currentDate.getFullYear() &&
              eventStart.getMonth() === currentDate.getMonth() &&
              eventStart.getDate() === currentDate.getDate()
            );
          } else {
            // Laptop view: Show events for the entire week  
            const { startOfWeek, endOfWeek } = getCurrentWeekStartEnd();
            return (
              eventStart &&
              eventStart >= startOfWeek &&
              eventStart <= endOfWeek
            );
          }
        });

        const serializableEventList = filteredEventList.map((event) => ({
          ...event,
          start: event.start ? event.start.getTime() : null,
          end: event.end ? event.end.getTime() : null,
          date: event.date ? event.date.getTime() : null,
        }));
        setLoader(false);
        // Dispatch the converted events  
        setEvents(filteredEventList); // Set events state  
        setFilteredEvents(filteredEventList); // Set filtered events state  
      },
      (error) => {
        addToast(error.message, "error"); // Handle errors  
      }
    );
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    // Clean up listeners on unmount
    return () => {
      resourcesUnsubscribe();
      eventsUnsubscribe();
      window.removeEventListener("resize", handleResize);
    };
  }, [db, dispatch, setLoader, addToast, selectedDate]);

  const handleResourceChange = useCallback(
    (e) => {
      const selectedEmail = e?.target?.value;
      setSelectedEmail(selectedEmail);

      const filteredEvents = events.filter(
        (event) => event.resourceEmail === selectedEmail
      );
      const deserializedEvents = filteredEvents.map((event) => ({
        ...event,
        start: event?.start ? Timestamp.fromDate(new Date(event?.start)) : null,
        end: event?.end ? Timestamp.fromDate(new Date(event?.end)) : null,
        date: event?.date ? Timestamp.fromDate(new Date(event?.date)) : null,
      }));

      const serializableFilteredEventList = deserializedEvents.map((event) => ({
        ...event,
        start: event.start ? new Date(event.start.seconds * 1000) : null,
        end: event.end ? new Date(event.end.seconds * 1000) : null,
        date: event.date ? new Date(event.date.seconds * 1000) : null,
      }));

      setFilteredEvents(serializableFilteredEventList);
      setSelectedEmail(e?.target?.value);
      dispatch(setSelectedEmailSlice(e?.target?.value));
      // dispatch(setFilteredEventsValue(serializableFilteredEventList)); // Dispatch filtered events
    },
    [events, dispatch, selectedEmail]
  );

  useEffect(() => {
    // Call the implementCustomEvent function whenever filteredEvents changes

    const handleNextButtonClick = (event) => {
      // Check if the clicked element is the "Next" button
      if (
        (event.target && event.target.textContent === "Next") ||
        event.target.textContent === "Back"
      ) {
        implementCustomEvent();
      }
    };

    // Add event listener to the document
    document.addEventListener("click", handleNextButtonClick);

    setTimeout(() => {
      implementCustomEvent(filteredEvents);
    }, 1000);
    return () => {
      document.removeEventListener("click", handleNextButtonClick);
    };
  }, [filteredEvents]); // Dependency array to watch for changes in filteredEvents

  const implementCustomEvent = () => {
    filteredEvents.forEach((event, i) => {
      const eventDiv = document?.querySelector(
        `div[role="button"][title*="${event.title}"]`
      );

      if (eventDiv) {
        // Find the div with className "rbc-event-label" inside the found eventDiv
        const labelDiv = eventDiv?.querySelector(".rbc-event-label");
        // Remove the height property of the labelDiv
        if (labelDiv) {
          labelDiv.style.marginTop = "-0.5rem";
          // labelDiv.title = event.title; // Set the title attribute of the labelDiv to the event title
          let text = !checkIfTempUser() || event?.createdBy == user?.emailId ? `
          ${event?.candidateName.split(" ")[0]} - ${event?.roundDetails} - ${event?.companyName.split(" ")[0]
            }<br/>
          ${event?.companyName} \n
          `: `<br>Slot is Booked</br>`;
          return (labelDiv.innerHTML = text ?? `<br>Slot is Booked</br>`); // Return the inner HTML if needed
        }
      }
    });
  };

  const checkIfTempUser = () => user?.role == "TEMPORARY";

  const handleDateChange = useCallback(
    (e) => {
      const date = new Date(e.target.value);
      setSelectedDate(date);

      const unsubscribe = onSnapshot(
        collection(db, "BDSchedules"),
        (querySnapshot) => {
          const isMobile = window.innerWidth <= 768; // Adjust based on your mobile breakpoint  
          const currentDate = date;  // Use the newly selected date instead of selectedDate  

          const eventList = querySnapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              ...data,
              start: data?.start?.seconds ? new Date(data.start.seconds * 1000) : null,
              end: data?.end?.seconds ? new Date(data.end.seconds * 1000) : null,
              date: data?.date?.seconds ? new Date(data.date.seconds * 1000) : null,
            };
          });

          // Filter events based on the current view  
          const filteredEventList = eventList.filter((event) => {
            const eventStart = event.start;
            if (isMobile) {
              // Mobile view: Show events that start today  
              return (
                eventStart &&
                eventStart.getFullYear() === currentDate.getFullYear() &&
                eventStart.getMonth() === currentDate.getMonth() &&
                eventStart.getDate() === currentDate.getDate()
              );
            } else {
              // Laptop view: Show events for the entire week  
              const { startOfWeek, endOfWeek } = getCurrentWeekStartEnd();
              return (
                eventStart &&
                eventStart >= startOfWeek &&
                eventStart <= endOfWeek
              );
            }
          });

          const serializableEventList = filteredEventList.map((event) => ({
            ...event,
            start: event.start ? event.start.getTime() : null,
            end: event.end ? event.end.getTime() : null,
            date: event.date ? event.date.getTime() : null,
          }));

          // Update the state with the newly fetched events and filtered events  
          setEvents(serializableEventList);
          setFilteredEvents(serializableEventList.filter(event => {
            // Filter for the newly selected date  
            const eventDate = new Date(event.date);
            return moment(eventDate).isSame(date, "day");
          }));
        },
        (error) => {
          addToast(error.message, "error"); // Handle errors  
        }
      );

      // This line allows you to clean up the subscription when the component unmounts or changes  
      return () => unsubscribe();
    },
    [selectedDate]
  );

  const handleSelectSlot = useCallback(
    (slotInfo) => {
      const newDate = moment(slotInfo.start).toDate();
      if (!selectedEmail) {
        addToast("Please select a resource first", "warning");
      } else {
        setSelectedDate(newDate);
        setSelectedSlotStartTime(moment(slotInfo.start));
        setSelectedSlotEndTime(moment(slotInfo.start).add(1, "hour"));
        setSelectedEvent(null);
        setModalOpen(true);
      }
    },
    [selectedEmail]
  );

  const handleEventClick = useCallback(
    (event) => {
      if (!selectedEmail) {
        addToast("Please select a resource first", "warning");
      } else {
        if (user?.role !== "TEMPORARY" || event?.createdBy == user?.emailId) {
          setSelectedEvent(event);
          setIsUpdate(true);
          setModalOpen(true);
        } else {
          return;
        }
      }
    },
    [selectedEmail]
  );

  const handleSaveEvent = async (eventData) => {
    if (selectedEvent) {
      selectedEvent.resourceEmail = selectedEmail;
      await updateSchedule(selectedEvent.id, eventData);
      const updatedEvents = filteredEvents.map((event) =>
        event.id === selectedEvent.id ? { ...event, ...eventData } : event
      );

      const deserializedUpdatedEvents = updatedEvents.map((event) => ({
        ...event,
        start: event.start ? event.start.getTime() : null,
        end: event.end ? event.end.getTime() : null,
        date: event.date ? event.date.getTime() : null,
      }));

      setFilteredEvents(updatedEvents);
      dispatch(setFilteredEventsValue(deserializedUpdatedEvents)); // Dispatch filtered events
      handleClose();
    } else {
      eventData['createdBy'] = user?.emailId ?? '';
      const newId = await addNewSchedule(eventData);
      if (newId) {
        const newEvent = { ...eventData, id: newId };
        const updatedEvents = [...filteredEvents, newEvent];
        setFilteredEvents(updatedEvents);
        dispatch(setFilteredEventsValue(updatedEvents)); // Dispatch filtered events
        handleClose();
      }
    }
  };

  const handleDeleteEvent = async (event) => {
    try {
      await deleteDoc(doc(db, "BDSchedules", event.id));
      const updatedEvents = filteredEvents.filter((e) => e.id !== event.id);
      setFilteredEvents(updatedEvents);
      addToast("Event deleted successfully", "success");
    } catch (error) {
      addToast(error.message, "error");
    }
  };

  const addNewSchedule = async (data) => {
    const newScheduleRef = doc(collection(db, "BDSchedules"));
    await setDoc(newScheduleRef, { id: newScheduleRef.id, ...data });
    return newScheduleRef.id;
  };

  const updateSchedule = async (id, data) => {
    await updateDoc(doc(db, "BDSchedules", id), data);
  };

  const handleClose = () => {
    setModalOpen(false);
    setIsUpdate(false);
    setSelectedEvent(null);
  };

  const addToastFn = (message, type) => {
    addToast(message, type)
  }

  // Function to customize event styles based on their properties
  const eventStyleGetter = (event) => {
    return {
      style: {
        backgroundColor: event.color || "#3174ad", // Default color if no color is specified
        borderRadius: "8px",
        color: "white",
        border: "none",
        textAlign: "left",
        padding: "10px",
        cursor: "pointer",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      },
    };
  };

  const getNavigationDirection = (date) => {
    const currentDate = new Date(selectedDate);
    const nextDate = new Date(date);

    const currentDateStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    const nextDateStart = new Date(nextDate.getFullYear(), nextDate.getMonth(), nextDate.getDate());

    if (nextDateStart.getTime() > currentDateStart.getTime()) {
      return "tomorrow"; // Navigating forward  
    } else if (nextDateStart.getTime() < currentDateStart.getTime()) {
      return "yesterday"; // Navigating backward  
    } else {
      return "today"; // Today (no navigation)  
    }
  };

  return (
    <div className="schedules-container w-full p-3 bg-white rounded-lg shadow-lg">
      <div className="w-full bg-gray-100 p-2 mb-3 rounded-md">
        <h2
          className="title text-center text-2xl font-bold mb-2"
          style={{ color: "#0071bd" }} // Directly applying the color style
        >
          Interview Schedules
        </h2>
      </div>
      <div className="controls-container grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
        <div className="control">
          <label
            htmlFor="select-resource"
            className="block text-sm font-medium text-gray-700"
          >
            Select Resource
          </label>
          <select
            id="select-resource"
            value={selectedEmail}
            onChange={handleResourceChange}
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
          >
            <option value="" disabled>
              Select a Resource
            </option>
            {resourcesData.map((resource, index) => (
              <option key={index} value={resource?.emailId}>
                {resource?.fullName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="calendar-wrapper">
        <Calendar
          localizer={localizer}
          events={filteredEvents.map((event) => ({
            ...event,
            title: event.title.replace(
              /^\d{1,2}:\d{2}\s(AM|PM)\s-\s\d{1,2}:\d{2}\s(AM|PM)\s/,
              ""
            ), // Remove the time range from the title
          }))}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 900, width: "100%" }}
          views={isSmallScreen ? ["day"] : ["week"]}
          defaultView={isSmallScreen ? "day" : "week"}
          selectable
          components={{ event: CustomEvent }} // Use the custom event component
          onSelectSlot={handleSelectSlot}
          onSelectEvent={handleEventClick}
          eventPropGetter={eventStyleGetter} // Use the custom event style getter
          min={new Date(0, 0, 0, 7, 0, 0)}
          max={new Date(0, 0, 0, 22, 0, 0)}
          onNavigate={(date) => {
            const direction = getNavigationDirection(date); // Determine direction  
            if (direction === "tomorrow") {
              handleDateChange({ target: { value: date } }); // Update state for next day  
            } else if (direction === "yesterday") {
              handleDateChange({ target: { value: date } }); // Update state for previous day  
            }

            setSelectedDate(date); // Update selected date to sync with calendar  
          }}
        />
      </div>

      {/* Modal for Event Editing/Creating */}
      <EventModal
        event={selectedEvent}
        selectedSlotStartTime={selectedSlotStartTime}
        selectedSlotEndTime={selectedSlotEndTime}
        isOpen={modalOpen}
        onClose={handleClose}
        onSave={handleSaveEvent}
        onDelete={handleDeleteEvent}
        isUpdate={isUpdate}
        candidates={candidates}
        addToast={addToastFn}
      />
    </div>
  );
};

export default Schedules;
