import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Plus, Search } from "lucide-react";
// import { RootState } from "../../store/store";
import CandidateTable from "../Candidates/CandidateTable";
import {
  getDocs,
  collection,
  getFirestore,
  deleteDoc,
  doc,
} from "firebase/firestore";
//@ts-ignore
import { app } from "../../firebase";
import { getAuth } from "firebase/auth";
import {
  setCandidates,
  setSelectedCandidate,
} from "../../store/candidatesSlice";
import CandidateGrid from "./CandidateList/CandidateGrid";
//@ts-ignore
import CandidateFormAddOrEdit from "../AddOrEditCandidate";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
//@ts-ignore
import CandidateSearchExport from "../CandidateSearchExport";
//@ts-ignore
import ConfirmationPopup from "../ConfirmationPopup";

const CandidateList: React.FC<any> = ({ addToast, setLoader, filter }) => {
  const [view, setView] = useState<"grid" | "table">("grid");
  const candidates = useSelector((state: any) => state.candidates.candidates);
  const [isOpenAddCandidate, setIsOpenAddCandidate] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const db = getFirestore(app);
  const auth = getAuth(app);
  const user = useSelector((state: any) => state.auth.user);
  const isSuperAdmin = user?.role === "SUPERADMIN";
  const isAccentureTeam = user?.role === "ACCENTURETEAM";
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const filterCandidates = (
    candidates: any[],
    user: any,
    filter: string,
    searchQuery: string
  ) => {
    let filtered = candidates;

    // If the user is a SUPERADMIN, return all candidates
    // if (isSuperAdmin) {
    //   filtered = candidates; // Return all candidates for SUPERADMIN
    // } else if (user?.emailId) {
    //   // Filter based on createdBy and createdRole
    //   filtered = candidates.filter(candidate =>
    //     candidate.createdBy === user.emailId ||
    //     candidate.createdRole === user.role
    //   );
    // } else {
    //   return []; // Return an empty array if no criteria match
    // }

    // Apply additional filtering based on the filter prop and search query
    return filtered.filter((candidate: any) => {
      const matchesFilter =
        filter === "all"
          ? !candidate.isSelected && !candidate.isOffered && !candidate.isPayed
          : filter === "selected"
            ? candidate.isSelected && !candidate.isOffered && !candidate.isPayed
            : filter === "offered"
              ? candidate.isOffered && !candidate.isPayed
              : candidate.isPayed;

      const matchesSearch =
        searchQuery.toLowerCase() === ""
          ? true
          : candidate.fullName
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          candidate.technology
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          candidate.emailId.toLowerCase().includes(searchQuery.toLowerCase());

      const isAccentureTeamCandidate =
        candidate.createdRole === "ACCENTURETEAM";

      if (user.role === "ACCENTURETEAM") {
        return matchesFilter && matchesSearch && isAccentureTeamCandidate;
      } else {
        return matchesFilter && matchesSearch; // For roles other than "ACCENTURETEAM"
      }
    });
  };

  const filteredCandidates = filterCandidates(
    candidates,
    user,
    filter,
    searchQuery
  );

  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState<any>(null);
  const [dataToDelete, setDataToDelete] = useState<any>(null);

  const handleDelete = () => {
    setIsConfirmDeleteOpen(true);
  };

  const handleDeleteConfirm = async () => {
    setIsConfirmDeleteOpen(false);
    setLoader(true);
    deleteCandidate(dataToDelete.id);
  };


  const handleCancel = () => {
    setIsConfirmDeleteOpen(false);
  };

  const handleEditCandidate = (candidate: any) => {
    dispatch(setSelectedCandidate(candidate));
    navigate("/createorupdate");
    setIsOpenAddCandidate(true);
  };

  const handleDeleteCandidate = (candidate: any) => {
    setDataToDelete(candidate);
    handleDelete();
  };

  useEffect(() => {
    if (user?.emailId == "mahi@bytesplash.in") {
      localStorage.clear();
      window.location.href = "/login"
    }
    const fetchCandidates = async () => {
      setLoader(true);
      try {
        const querySnapshot = await getDocs(collection(db, "BDProfiles"));
        const candidatesData: any = querySnapshot.docs.map((doc) => doc.data());
        dispatch(setCandidates(candidatesData));
        // addToast('Success', 'success');
        setLoader(false);
      } catch (error: any) {
        addToast(error.error, "error");
        setLoader(false);
      }
    };

    fetchCandidates();
  }, [db, dispatch, setLoader]);

  const fetchCandidates = async () => {
    setLoader(true);
    try {
      const querySnapshot = await getDocs(collection(db, "BDProfiles"));
      const candidatesData: any = querySnapshot.docs.map((doc) => doc.data());
      dispatch(setCandidates(candidatesData));
      // addToast('Success', 'success');
      setLoader(false);
    } catch (error: any) {
      addToast(error.error, "error");
      setLoader(false);
    }
  };

  const deleteCandidate = async (id: any) => {
    try {
      await deleteDoc(doc(db, "BDProfiles", id));
      addToast("Candidate deleted successfully", "success");
      fetchCandidates();
    } catch (error: any) {
      addToast(error.message, "error");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="space-y-4 p-1">
      {/* Confirmation and Form Modals */}
      {isConfirmDeleteOpen && (
        <ConfirmationPopup
          message="Are you sure you want to delete the data?"
          name={dataToDelete?.fullName ?? ""}
          onConfirm={handleDeleteConfirm}
          onCancel={handleCancel}
        />
      )}
      {isOpenAddCandidate && (
        <CandidateFormAddOrEdit
          setLoader={setLoader}
          onClose={() => setIsOpenAddCandidate(false)}
        />
      )}

      {/* Header Section */}
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
        <h1 className="flex items-center justify-between w-full max-w-md text-xl font-bold text-[#0071bd] bg-gray-100 p-3 rounded-md shadow-md">
          <span>
            {filter.charAt(0).toUpperCase() + filter.slice(1)} Candidates
          </span>
          <span className="bg-[#0071bd] text-white text-sm font-semibold px-2 py-1 rounded-full">
            {filteredCandidates.length}
          </span>
        </h1>
      </div>

      {/* Search and Actions Section */}
      <div className="flex flex-col gap-2">
        {/* Actions Row - Always in one line */}
        <div className="flex flex-row justify-between items-center gap-2 w-full">
          <div className="flex-shrink-0">
            {" "}
            {/* Prevent button from shrinking */}
            <NavLink to="/createorupdate" className="block">
              <button
                onClick={() => dispatch(setSelectedCandidate(null))}
                className="inline-flex items-center justify-center px-4 py-2 bg-[#0071bd] text-white rounded-lg hover:bg-[#0062a3] transition-colors text-sm"
              >
                <Plus className="w-4 h-4 mr-1" />
                Add Candidate
              </button>
            </NavLink>
          </div>

          <div className="flex-1">
            {" "}
            {/* Allow CandidateSearchExport to fill the remaining space */}
            <CandidateSearchExport
              user={user}
              filter={filter}
              isSuperAdmin={isSuperAdmin}
              isAccentureTeam={isAccentureTeam}
              filteredCandidates={filteredCandidates}
            />
          </div>
        </div>
        {/* Search Bar */}
        <div className="relative w-full">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="text"
            placeholder="Search candidates..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#0071bd] focus:border-transparent"
          />
        </div>
      </div>

      {/* Grid/Table View */}
      {isMobile || view === "grid" ? (
        <CandidateGrid
          onEdit={handleEditCandidate}
          onDelete={handleDeleteCandidate}
          setLoader={setLoader}
          candidates={filteredCandidates}
          isSuperAdmin={isSuperAdmin}
          isAccentureTeam={isAccentureTeam}
        />
      ) : (
        <CandidateTable candidates={filteredCandidates} />
      )}
    </div>
  );
};

export default CandidateList;
