import React, { useEffect, useState } from "react";  
import { useDispatch, useSelector } from "react-redux";  
import { getDocs, collection, getFirestore, deleteDoc, doc, updateDoc, setDoc } from "firebase/firestore";  
import { app } from "../../firebase";  
import { getAuth } from "firebase/auth";  
import { useNavigate } from "react-router-dom";

const BGVFormCreateOrEdit = ({ addToast, setLoader, onClose }) => {  
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state?.auth);  
  const [candidate, setCandidate] = useState<any>({  
    fullName: "",  
    emailId: "",  
    mobileNo: "",  
    paidAmount: "",  
    totalAmount: "", // Added Total Amount field  
    balanceAmount: "0", // Initialize balanceAmount to 0  
    isBGVCompleted: false,  
    isPaid: false,  
    id: ''  
  });  
  const selectedCandidate = useSelector((state: any) => state?.candidates.selectedCandidate);  

  useEffect(() => {  
    if (selectedCandidate) {  
      setCandidate({  
        id:selectedCandidate?.id || '',
        fullName: selectedCandidate?.fullName || "",  
        emailId: selectedCandidate?.emailId || "",  
        mobileNo: selectedCandidate?.mobileNo || "",  
        paidAmount: selectedCandidate?.paidAmount || "",  
        totalAmount: selectedCandidate?.totalAmount || "", // Initialize Total Amount from selectedCandidate  
        balanceAmount: selectedCandidate?.balanceAmount || "",  
        isBGVCompleted: selectedCandidate?.isBGVCompleted || false,  
        isPaid: selectedCandidate?.isPaid || false,  
      });  
    }  
  }, [selectedCandidate]);  

  useEffect(() => {  
    // Calculate balance automatically based on totalAmount and paidAmount  
    const totalAmount = parseFloat(candidate.totalAmount || "0");  
    const paidAmount = parseFloat(candidate.paidAmount || "0");  
    const balance = Math.max(0, totalAmount - paidAmount);  
    setCandidate((prev) => ({ ...prev, balanceAmount: balance.toString() }));  
  }, [candidate.paidAmount, candidate.totalAmount]);  

  const handleAddProfileSubmit = async (profileData: any) => {  
    setLoader(true);  
    const data = {  
      ...profileData,  
      createdBy: user?.emailId || null,  
      createdRole: user?.role || null,  
    };  

    try {  
      if (candidate?.id || selectedCandidate?.id) {  
        await updateCandidates(selectedCandidate.id, data);  
        addToast("Candidate updated successfully", "success");  
      } else {  
        await createCandidates(data);  
        addToast("Candidate added successfully", "success");  
      }  
      setLoader(false);  
      onClosed();  
    } catch (error) {  
      addToast("Error processing candidate", "error");  
      setLoader(false);  
    }  
  };  


  
  const onClosed = () => {
    navigate("/bgv/inprogress"); // Use navigate for routing
  };

  const updateCandidates = async (id, data) => {  
    await updateDoc(doc(getFirestore(app), "BGVProfiles", id), data);  
  };  

  const createCandidates = async (data) => {  
    const newCandidateRef = doc(collection(getFirestore(app), "BGVProfiles"));  
    await setDoc(newCandidateRef, {    
      ...data,  
      id: newCandidateRef.id
    });  
  };  

  const handleChange = (e) => {  
    const { name, value, type, checked } = e.target;  
    setCandidate({  
      ...candidate,  
      [name]: type === "checkbox" ? checked : value,  
    });  
  };  

  const handleSubmit = (e) => {  
    e.preventDefault();  
    handleAddProfileSubmit(candidate);  
  };  

  return (  
    <div className="overlay">  
      <div className="container p-6 bg-white rounded-lg shadow-md max-w-2xl mx-auto relative">  
        <button onClick={onClosed} className="absolute top-4 right-4 text-gray-600 hover:text-gray-800" aria-label="Close">  
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">  
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />  
          </svg>  
        </button>  

        <h2 className="text-xl font-bold text-[#0071bd] mb-4 bg-gray-100 p-2 mt-4 rounded-md">  
          {selectedCandidate?.id ? "Edit BGV Profile" : "Add BGV Profile"}  
        </h2>  

        <form onSubmit={handleSubmit}>  
          <div className="grid grid-cols-1 gap-4">  
            <div className="flex flex-col">  
              <label className="font-semibold">Full Name</label>  
              <input type="text" name="fullName" value={candidate.fullName} onChange={handleChange} className="border rounded p-2 mt-1" required />  
            </div>  

            <div className="flex flex-col">  
              <label className="font-semibold">Email ID</label>  
              <input type="email" name="emailId" value={candidate.emailId} onChange={handleChange} className="border rounded p-2 mt-1" required />  
            </div>  

            <div className="flex flex-col">  
              <label className="font-semibold">Mobile No</label>  
              <input type="text" name="mobileNo" value={candidate.mobileNo} onChange={handleChange} className="border rounded p-2 mt-1" required />  
            </div>  

            <div className="flex flex-col">  
              <label className="font-semibold">Total Amount</label>  
              <input type="number" name="totalAmount" value={candidate.totalAmount} onChange={handleChange} placeholder="Enter Total Amount" className="border rounded p-2 mt-1" required />  
            </div>  

            <div className="flex flex-col">  
              <label className="font-semibold">Paid Amount</label>  
              <input type="number" name="paidAmount" value={candidate.paidAmount} onChange={handleChange} placeholder="Enter Paid Amount" className="border rounded p-2 mt-1" required />  
            </div>  

            <div className="flex flex-col">  
              <label className="font-semibold">Balance Amount</label>  
              <input type="number" name="balanceAmount" value={candidate.balanceAmount} disabled className="border rounded p-2 mt-1" required />  
            </div>  

            <div className="flex flex-col space-x-4 mt-1">  
              <label className="font-semibold">BGV Completed</label>  
              <div className="relative inline-flex items-center">  
                <label className="flex items-center cursor-pointer">  
                  <input  
                    type="checkbox"  
                    name="isBGVCompleted"  
                    checked={candidate.isBGVCompleted}  
                    onChange={handleChange}  
                    className="sr-only"  
                  />  
                  <div className="w-14 h-8 bg-gray-300 rounded-full shadow-inner"></div>  
                  <div  
                    className={`absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-200 ease-in-out ${  
                      candidate.isBGVCompleted ? "translate-x-full bg-[#0071bd]" : "bg-gray-500"  
                    }`}  
                  ></div>  
                </label>  
              </div>  
            </div>  

            <div className="flex flex-col space-x-4 mt-1">  
              <label className="font-semibold">Paid Status</label>  
              <div className="relative inline-flex items-center">  
                <label className="flex items-center cursor-pointer">  
                  <input  
                    type="checkbox"  
                    name="isPaid"  
                    checked={candidate.isPaid}  
                    onChange={handleChange}  
                    className="sr-only"  
                  />  
                  <div className="w-14 h-8 bg-gray-300 rounded-full shadow-inner"></div>  
                  <div  
                    className={`absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-200 ease-in-out ${  
                      candidate.isPaid ? "translate-x-full bg-[#0071bd]" : "bg-gray-500"  
                    }`}  
                  ></div>  
                </label>  
              </div>  
            </div>  
          </div>  

          <div className="flex justify-between mt-4">  
            <button type="submit" className="bg-[#0071bd] text-white px-4 py-2 rounded">  
              Submit  
            </button>  
            <button type="button" onClick={onClosed} className="bg-gray-300 text-gray-800 px-4 py-2 rounded">  
              Cancel  
            </button>  
          </div>  
        </form>  
      </div>  
    </div>  
  );  
};  

export default BGVFormCreateOrEdit;  