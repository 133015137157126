import React, { useEffect, useState } from "react";  
import { useDispatch, useSelector } from "react-redux";  
import { Plus, Search } from "lucide-react";  
import {  
    getDocs,  
    collection,  
    getFirestore,  
    deleteDoc,  
    doc,  
} from "firebase/firestore";  
import { app } from "../../firebase";  
import { getAuth } from "firebase/auth";  
import {  
    setCandidates,  
    setSelectedCandidate,  
} from "../../store/candidatesSlice";  
import CandidateGrid from "../Candidates/CandidateList/CandidateGrid";  
import CandidateFormAddOrEdit from "../AddOrEditCandidate";  
import { NavLink, useNavigate } from "react-router-dom";  
import CandidateSearchExport from "../CandidateSearchExport";  
import ConfirmationPopup from "../ConfirmationPopup";  
import CandidateTable from "../Candidates/CandidateList/CandidateTable";  
import BGVFormCreateOrEdit from "./BGVFormCreateOrEdit";  
import BGVGrid from "./BGVGrid";  

const BGVList: React.FC<any> = ({ addToast, setLoader, filter }) => {  
    const [view, setView] = useState<"grid" | "table">("grid");  
    const [isOpenAddBGV, setIsOpenAddBGV] = useState(false);  
    const [searchQuery, setSearchQuery] = useState("");  
    const navigate = useNavigate();  
    const dispatch = useDispatch();  
    const db = getFirestore(app);  
    const user = useSelector((state: any) => state.auth.user);  
    const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState<boolean>(false);  
    const [dataToDelete, setDataToDelete] = useState<any>(null);  
    const [bgvData, setBgvData] = useState<any[]>([]);  

    // Function to fetch BGV profiles  
    const fetchBGVProfiles = async () => {  
        setLoader(true);  
        try {  
            const querySnapshot = await getDocs(collection(db, "BGVProfiles"));  
            const profilesData: any = querySnapshot.docs.map((doc) => ({  
                id: doc.id,  
                ...doc.data(),  
            }));  
            setBgvData(profilesData);  
        } catch (error: any) {  
            addToast(error.message, "error");  
        } finally {  
            setLoader(false);  
        }  
    };  

    useEffect(() => {  
        fetchBGVProfiles();  
    }, []);  

    const handleDelete = () => {  
        setIsConfirmDeleteOpen(true);  
    };  

    const handleDeleteConfirm = async () => {  
        if (dataToDelete) {  
            setIsConfirmDeleteOpen(false);  
            await deleteCandidate(dataToDelete.id);  
        }  
    };  

    const deleteCandidate = async (id: any) => {  
        try {  
            await deleteDoc(doc(db, "BGVProfiles", id));  
            addToast("BGV Profile deleted successfully", "success");  
            fetchBGVProfiles();  
        } catch (error: any) {  
            addToast(error.message, "error");  
        }  
    };  

    const handleCancel = () => {  
        setIsConfirmDeleteOpen(false);  
    };  

    const handleEditBGV = (bgv: any) => {  
        dispatch(setSelectedCandidate(bgv));  
        navigate("/bgvcreateorupdate");  
        setIsOpenAddBGV(true);  
    };  

    const handleDeleteBGV = (bgv: any) => {  
        setDataToDelete(bgv);  
        handleDelete();  
    };  

    const filteredBGVData = bgvData.filter((bgv) => {  
        const matchesFilter =  
            filter === "InProgress"  
                ? !bgv.isBGVCompleted && !bgv.isPaid  
                : filter === "Completed"  
                    ? bgv.isBGVCompleted && !bgv.isPaid  
                    : filter === "Paid"  
                        ? bgv.isBGVCompleted && bgv.isPaid  
                        : bgv.isPaid;  

        const matchesSearch =  
            searchQuery?.toLowerCase() === "" ||  
            bgv?.fullName.toLowerCase().includes(searchQuery?.toLowerCase()) ||  
            bgv?.emailId.toLowerCase().includes(searchQuery?.toLowerCase()) ||  
            bgv?.mobileNo.includes(searchQuery);  

        return matchesFilter && matchesSearch;  
    });  

    return (  
        <div className="space-y-4 p-1 h-screen">  
            {isConfirmDeleteOpen && (  
                <ConfirmationPopup  
                    message="Are you sure you want to delete the data?"  
                    name={dataToDelete?.fullName ?? ""}  
                    onConfirm={handleDeleteConfirm}  
                    onCancel={handleCancel}  
                />  
            )}  
            {isOpenAddBGV && (  
                <BGVFormCreateOrEdit  
                    addToast={addToast}  
                    setLoader={setLoader}  
                    onClose={() => setIsOpenAddBGV(false)}  
                />  
            )}  

            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">  
                <h1 className="flex items-center justify-between w-full max-w-md text-xl font-bold text-[#0071bd] bg-gray-100 p-3 rounded-md shadow-md">  
                    <span>BGV Profiles</span>  
                    <span className="bg-[#0071bd] text-white text-sm font-semibold px-2 py-1 rounded-full">  
                        {filteredBGVData.length}  
                    </span>  
                </h1>  
            </div>  

            <div className="flex flex-col gap-2">  
                <div className="flex flex-row justify-between items-center gap-2 w-full">  
                    <div className="flex-shrink-0">  
                        <NavLink to="/bgvcreateorupdate" className="block">  
                            <button  
                                onClick={() => dispatch(setSelectedCandidate(null))}  
                                className="inline-flex items-center justify-center px-4 py-2 bg-[#0071bd] text-white rounded-lg hover:bg-[#0062a3] transition-colors text-sm"  
                            >  
                                <Plus className="w-4 h-4 mr-1" />  
                                Add BGV Profile  
                            </button>  
                        </NavLink>  
                    </div>  

                    <div className="flex-1">  
                        {/* <CandidateSearchExport  
                            user={user}  
                            filter={filter}  
                            isSuperAdmin={user?.role === "SUPERADMIN"}  
                            isAccentureTeam={user?.role === "ACCENTURETEAM"}  
                            filteredCandidates={filteredBGVData}  
                        />   */}
                    </div>  
                </div>  

                <div className="relative w-full">  
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />  
                    <input  
                        type="text"  
                        placeholder="Search BGV profiles..."  
                        value={searchQuery}  
                        onChange={(e) => setSearchQuery(e.target.value)}  
                        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#0071bd] focus:border-transparent"  
                    />  
                </div>  
            </div>  

            {filteredBGVData.length === 0 ? (  
                <div className="p-4 text-center text-gray-600">  
                    <p>No records found</p>  
                </div>  
            ) : (  
                view === "grid" ? (  
                    <BGVGrid  
                        bgvProfiles={filteredBGVData}  
                        onEdit={handleEditBGV}  
                        onDelete={handleDeleteBGV}  
                        isSuperAdmin={user?.role === "SUPERADMIN"}  
                        isBGVTeam={user?.role === "BGVTEAM"}  
                    />  
                ) : (  
                    <CandidateTable  
                        candidates={filteredBGVData}  
                        onEdit={handleEditBGV}  
                        onDelete={handleDeleteBGV}  
                    />  
                )  
            )}  
        </div>  
    );  
};  

export default BGVList;  