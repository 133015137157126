
import { Trash2 } from "lucide-react";  
import React, { useEffect, useState } from "react";  
import "./css/AddOrEditTransaction.css"; // Import your custom CSS for styling  
import { CustomIconCreditCardIcon, CustomIconVisaIcon, GooglePayIcon, HandCashIcon, InternetBankingIcon, PhonePeIconNew } from "./Icons";  
import { ChevronDown } from "lucide-react";  

const AddOrEditTransaction = ({  
  vendors,  
  transaction,  
  isOpen,  
  onClose,  
  onSave,  
  onDelete,  
}) => {  
  const [transactionData, setTransactionData] = useState({  
    sender: {  
      name: "",  
      dateTime: new Date().toISOString().split("T")[0], // Default to today's date  
      mobile: "",  
      amount: "",  
      mode: "",  
      time: "",  
    },  
    receiver: {  
      name: "",  
      mobile: "",  
    },  
    vendorName: "",  
    vendorEmailId: "",  
    vendorMobile: "",  
  });  

  const [isFormValid, setIsFormValid] = useState(false); // State to track form validity

  useEffect(() => {  
    if (transaction) {  
      setTransactionData({  
        id: transaction?.id,  
        sender: {  
          name: transaction.sender.name || "",  
          dateTime: transaction.sender.dateTime || "",  
          mobile: transaction.sender.mobile || "",  
          amount: transaction.sender.amount || "",  
          mode: transaction.sender.mode || "",  
        },  
        receiver: {  
          name: transaction.receiver.name || "",  
          mobile: transaction.receiver.mobile || "",  
        },  
        vendorName: transaction.vendorName || "",  
        vendorEmailId: transaction.vendorEmailId || "",  
        vendorMobile: transaction.vendorMobile || "",  
      });  
    } 
    scrollToTop();
  }, [transaction]);  

  useEffect(() => {
    // Validate form whenever transactionData changes
    const isValid = isFormValidFunc();
    setIsFormValid(isValid);
  }, [transactionData]);  

  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  
  const isFormValidFunc = () => {
    const { sender, receiver, vendorName } = transactionData;
    return (
      sender.name &&
      sender.mobile &&
      sender.amount &&
      sender.mode &&
      receiver.name &&
      receiver.mobile &&
      vendorName
    );
  };

  if (!isOpen) return null; // Don't render if not open  

  const handleChange = (e) => {  
    const { name, value } = e.target;  
    setTransactionData((prevData) => ({  
      ...prevData,  
      [name]: value,  
    }));  
  };  

  const handleSenderChange = (e) => {  
    const { name, value } = e.target;  
    setTransactionData((prevData) => ({  
      ...prevData,  
      sender: {  
        ...prevData.sender,  
        [name]: value,  
      },  
    }));  
  };  

  const handleOptionChange = (value) => {  
    setTransactionData((prevData) => ({  
      ...prevData,  
      sender: {  
        // Update the sender object with the selected mode  
        ...prevData.sender,  
        mode: value,  
      },  
    }));  
  };  

  const handleReceiverChange = (e) => {  
    const { name, value } = e.target;  
    setTransactionData((prevData) => ({  
      ...prevData,  
      receiver: {  
        ...prevData.receiver,  
        [name]: value,  
      },  
    }));  
  };  

  const handleVendorChange = (e) => {  
    const selectedVendor = vendors.find(vendor => vendor.vendorName === e.target.value);  
    if (selectedVendor) {  
      setTransactionData((prevData) => ({  
        ...prevData,  
        vendorName: selectedVendor.vendorName,  
        vendorEmailId: selectedVendor.vendorEmail, // Update email based on selected vendor  
        vendorMobile: selectedVendor.vendorMobile || "", // Optional: update vendor mobile if available  
      }));  
    }  
  };  

  const handleDelete = () => {  
    // onDelete(transaction.id); // Pass the transaction to the onDelete callback  
    onClose(); // Close the modal  
  };  

  const handleSave = () => {  
    onSave(transactionData); // Pass the complete transaction data to the onSave callback  
    onClose(); // Close the modal after saving  
  };  

  return (  
    <div className="modal-overlay-transaction">  
      <div className="modal-content-transaction w-full h-full p-4 ">  
        <button className="close-button" onClick={()=>onClose()}>  
          <svg  
            xmlns="http://www.w3.org/2000/svg"  
            className="h-6 w-6"  
            fill="none"  
            viewBox="0 0 24 24"  
            stroke="currentColor"  
          >  
            <path  
              strokeLinecap="round"  
              strokeLinejoin="round"  
              strokeWidth={2}  
              d="M6 18L18 6M6 6l12 12"  
            />  
          </svg>  
        </button>  
        <h2 className="text-2xl font-bold text-[#0071bd] mb-2 text-center">  
          {transaction ? "Edit Transaction" : "Add Transaction"}  
        </h2>  

        <div className="transaction-details p-4 h-full overflow-y-auto">  
          <h3 className="text-lg font-semibold mb-2">Sender Details</h3>  
          <input  
            type="text"  
            name="name"  
            placeholder="Sender Name"  
            value={transactionData.sender.name}  
            onChange={handleSenderChange}  
            className="event-input mb-2"  
            required
          />  
          <input  
            type="text"  
            name="mobile"  
            placeholder="Sender Mobile"  
            value={transactionData.sender.mobile}  
            onChange={handleSenderChange}  
            className="event-input mb-2"  
            required
          />  
          <input  
            type="text"  
            name="amount"  
            placeholder="Amount"  
            value={transactionData.sender.amount}  
            onChange={handleSenderChange}  
            className="event-input mb-2"  
            required
          />  
          <CustomDropdown  
            value={transactionData.sender.mode}  
            onChange={handleOptionChange}  
          />  
          <input  
            type="datetime-local"  
            name="dateTime"  
            value={transactionData.sender.dateTime}  
            onChange={handleSenderChange}  
            className="event-input mb-2"  
          />  

          <h3 className="text-lg font-semibold mb-2">Receiver Details</h3>  
          <input  
            type="text"  
            name="name"  
            placeholder="Receiver Name"  
            value={transactionData.receiver.name}  
            onChange={handleReceiverChange}  
            className="event-input mb-2"  
            required
          />  
          <input  
            type="text"  
            name="mobile"  
            placeholder="Receiver Mobile"  
            value={transactionData.receiver.mobile}  
            onChange={handleReceiverChange}  
            className="event-input mb-2"  
            required
          />  

          <h3 className="text-lg font-semibold mb-2">Vendor Details</h3>  
          <select  
            name="vendorName"
            required  
            value={transactionData.vendorName}  
            onChange={handleVendorChange}  
            className="event-input mb-2"  
          >  
            <option value="">Select Vendor</option>  
            {vendors.map((vendor) => (  
              <option key={vendor.vendorEmail} value={vendor.vendorName}>  
                {vendor.vendorName}  
              </option>  
            ))}  
          </select>  

          <div className="modal-buttons mt-4 flex flex-col">  
            <button 
              onClick={handleSave} 
              className={`join-button mb-0 bg-[#0071bd] text-white rounded shadow-lg py-2 hover:bg-[#005f9e] ${!isFormValid ? 'opacity-50 cursor-not-allowed' : ''}`} 
              disabled={!isFormValid} // Disable if form is not valid
            >  
              Save  
            </button>  
            {/* {transaction && (  
              <button onClick={handleDelete} className="join-button mb-0 vertical-align-middle bg-[#b5651d] text-white rounded shadow-lg py-2 hover:bg-[#9f4e16]">  
                Delete  
              </button>  
            )}   */}
            <button onClick={onClose} className="mt-0 cancel-button bg-gray-200 text-gray-800 rounded shadow-lg py-2 hover:bg-gray-300">  
              Cancel  
            </button>  
          </div>  
        </div>  
      </div>  
    </div>  
  );  
};  

export default AddOrEditTransaction;  

const CustomDropdown = ({ value, onChange }) => {  
  const options = [  
    { value: "phonepe", label: "PhonePe", icon: <PhonePeIconNew /> },  
    { value: "googlepay", label: "Google Pay", icon: <GooglePayIcon /> },  
    {  
      value: "netbanking",  
      label: "Net Banking",  
      icon: <InternetBankingIcon />,  
    },  
    {
      value:"cash",
      label:"Cash",
      icon:< HandCashIcon />
    },
    {
      value:"card",
      label:"Card",
      icon:<><CustomIconCreditCardIcon className="w-5 h-5 text-gray-600 mr-1" /> 
    &nbsp;<CustomIconVisaIcon  className="w-5 h-5 text-gray-600" /></>
    },
  ];  

  const [isOpen, setIsOpen] = useState(false);  

  // Toggle dropdown visibility  
  const toggleDropdown = () => setIsOpen(!isOpen);  

  // Handle option selection  
  const handleOptionSelect = (selectedOption) => {  
    onChange(selectedOption); // Update the parent component  
    setIsOpen(false); // Close dropdown after selection  
  };  

  return (  
    <div className="relative">  
      <div  
        onClick={toggleDropdown}  
        className="event-input mb-2 cursor-pointer flex justify-between items-center"  
        tabIndex={0} // Make it focusable  
      >  
        <div className="flex items-center">  
          {options.find((option) => option.value === value)?.icon}  
          <span className="ml-2">  
            {options.find((option) => option.value === value)?.label ||  
              "Select Payment Mode"}  
          </span>  
        </div>  
        {/* Chevron Down icon from lucide-react */}  
        <ChevronDown size={24} className="text-gray-500" />  
      </div>  

      {isOpen && (  
        <div className="absolute bg-white shadow-lg mt-1 w-full z-10 max-h-60 overflow-y-auto">  
          {options.map((option) => (  
            <div  
              key={option.value}  
              onClick={() => handleOptionSelect(option.value)}  
              className="flex items-center cursor-pointer p-2 hover:bg-gray-100"  
            >  
              {option.icon}  
              <span className="ml-2">{option.label}</span>  
            </div>  
          ))}  
        </div>  
      )}  

      <select name="mode" value={value} onChange={onChange} className="hidden">  
        {options.map((option) => (  
          <option key={option.value} value={option.value} />  
        ))}  
      </select>  
    </div>  
  );  
};

