import React, { useEffect, useState } from "react";  
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";  
import { useDispatch } from "react-redux";  
import { initializeAuth } from "./store/authSlice";  
import Layout from "./components/Layout/Layout";  
//@ts-ignore  
import Login from "./components/Auth/Login";  
import CandidateList from "./components/Candidates/CandidateList";  
//@ts-ignore  
import Loader from "./components/Loader";  
//@ts-ignore  
import CandidateFormAddOrEdit from "./components/AddOrEditCandidate";  
//@ts-ignore  
import ScrollToTop from "./ScrollTop"; // Import the ScrollToTop component  
//@ts-ignore  
import Toaster from "./components/Toaster";  
//@ts-ignore  
import Schedules from "./components/Schedules";  
//@ts-ignore  
import Payslip from "./components/Payslips";  
//@ts-ignore  
import ExperienceLetter from "./components/ExperienceLetter";  
//@ts-ignore  
import CompletedSchedules from "./components/CompletedSchedules";  
//@ts-ignore  
import VendorPayments from "./components/VendorPayments";  
//@ts-ignore  
import AccenturePayments from "./components/AccenturePayments";  
import ProfilePage from "./components/Profiles/ProfilePage";  
import SchedulingProfilesPage from "./components/Daily Schedules/DailySchedulePage";  
import ExperienceProfilesPage from "./components/Profiles/ExperienceProfilePage";  
import CoefficientCalculator from "./components/CoefficientCalculation";  

import Excaltryi from "./components/NewDashboard";  
import CoursesFn from "./components/NewCourses";  
import RelievingLetter from "./components/ReleivingLetter";  
import BGVList from "./components/BGV/BGVList";  
import BGVFormCreateOrEdit from "./components/BGV/BGVFormCreateOrEdit";  

const App: React.FC = () => {  
  const dispatch = useDispatch();  
  const [loader, setLoader] = useState(false);  
  const [toasts, setToasts] = useState<any[]>([]);  
  const [user, setUser] = useState<any>(null);  

  const addToast = (message: any, type: any) => {  
    const id = Date.now();  
    setToasts((prev: any) => [{ id, message, type }]);  
    setTimeout(() => {  
      removeToast(id);  
    }, 3000);  
  };  

  useEffect(() => {  
    const userData = localStorage.getItem("user");  
    if (userData) {  
      setUser(JSON.parse(userData));  
    }  
  }, []);  

  const removeToast = (id: any) => {  
    setToasts((prev) => prev.filter((toast: any) => toast.id !== id));  
  };  

  useEffect(() => {  
    dispatch(initializeAuth());  
  }, [dispatch]);  

  const setUserData = (user: any) => {  
    setUser(user);  
  };  

  return (  
    <BrowserRouter>  
      {toasts.map((toast) => (  
        <Toaster  
          key={toast.id}  
          message={toast.message}  
          type={toast.type}  
          onClose={() => removeToast(toast.id)}  
        />  
      ))}  
      {loader && <Loader isLoading={loader} />}  
      <ScrollToTop />  
      <Routes>  
        <Route path="/login" element={<Login addToast={addToast} setLoader={setLoader} />} />  
        <Route path="/" element={<Layout />}>  
          <Route  
            index  
            element={  
              <Navigate  
                to={  
                  user?.role === "VENDOR"  
                    ? "/payments/vendors"  
                    : user?.role === "SUPERADMIN"  
                    ? "profiles/scheduledprofiles"  
                    : user?.role === "ADMIN"  
                    ? "/schedules/scheduled"  
                    : "/schedules/scheduled"  
                }  
                replace  
              />  
            }  
          />  
          <Route path="schedules">  
            <Route  
              path="scheduled"  
              element={  
                <Schedules  
                  namesList={[]}  
                  setLoader={setLoader}  
                  addToast={addToast}  
                  user={user}  
                />  
              }  
            />  
            <Route path="pay" element={<Payslip setLoader={setLoader} />} />  
            <Route path="relieving" element={<RelievingLetter setLoader={setLoader} />} />  
            <Route path="experience" element={<ExperienceLetter setLoader={setLoader} />} />  
            <Route  
              path="completed"  
              element={  
                <CompletedSchedules setLoader={setLoader} addToast={addToast} />  
              }  
            />  
          </Route>  
          <Route path="payments">  
            <Route  
              path="vendors"  
              element={  
                <VendorPayments  
                  setLoader={setLoader}  
                  addToast={addToast}  
                  user={user}  
                />  
              }  
            />  
            <Route  
              path="accenture"  
              element={  
                <AccenturePayments setLoader={setLoader} addToast={addToast} />  
              }  
            />  
          </Route>  
          <Route path="candidates">  
            <Route  
              path="createorupdate"  
              element={  
                <CandidateFormAddOrEdit  
                  addToast={addToast}  
                  setLoader={setLoader}  
                  onClose={() => {}}  
                />  
              }  
            />  
            <Route  
              path="all"  
              element={  
                <CandidateList  
                  addToast={addToast}  
                  setLoader={setLoader}  
                  filter="all"  
                />  
              }  
            />  
            <Route  
              path="selected"  
              element={  
                <CandidateList  
                  addToast={addToast}  
                  setLoader={setLoader}  
                  filter="selected"  
                />  
              }  
            />  
            <Route  
              path="offered"  
              element={  
                <CandidateList  
                  addToast={addToast}  
                  setLoader={setLoader}  
                  filter="offered"  
                />  
              }  
            />  
            <Route  
              path="paid"  
              element={  
                <CandidateList  
                  addToast={addToast}  
                  setLoader={setLoader}  
                  filter="paid"  
                />  
              }  
            />  
            <Route  
              path="onboarded"  
              element={  
                <CandidateList  
                  addToast={addToast}  
                  setLoader={setLoader}  
                  filter="paid"  
                />  
              }  
            />  
          </Route>  
          <Route path="profiles">  
            <Route  
              path="allstoredfreshers"  
              element={  
                <ProfilePage  
                  setLoader={setLoader}  
                  addToast={addToast}  
                  user={user}  
                />  
              }  
            />  
            <Route  
              path="allstoredexperience"  
              element={  
                <ExperienceProfilesPage  
                  setLoader={setLoader}  
                  addToast={addToast}  
                  user={user}  
                />  
              }  
            />  
            {user?.role === "SUPERADMIN" && (  
              <Route path="newdashboard" element={<CoursesFn />} />  
            )}  
            <Route  
              path="scheduledprofiles"  
              element={  
                <SchedulingProfilesPage  
                  setLoader={setLoader}  
                  addToast={addToast}  
                  user={user}  
                />  
              }  
            />  
          </Route>  
          <Route path="bgv">  
            <Route  
              path="InProgress"  
              element={  
                <BGVList  
                  setLoader={setLoader}  
                  addToast={addToast}  
                  user={user}  
                  filter="InProgress"  
                />  
              }  
            />  
            <Route  
              path="Completed"  
              element={  
                <BGVList  
                  setLoader={setLoader}  
                  addToast={addToast}  
                  user={user}  
                  filter="Completed"  
                />  
              }  
            />  
            <Route  
              path="Paid"  
              element={  
                <BGVList  
                  setLoader={setLoader}  
                  addToast={addToast}  
                  user={user}  
                  filter="Paid"  
                />  
              }  
            />  
          </Route>  
          <Route  
            path="bgvcreateorupdate"  
            element={  
              <BGVFormCreateOrEdit  
                addToast={addToast}  
                setLoader={setLoader}  
                onClose={() => {}}  
              />  
            }  
          />  
          <Route path="betting">  
            {(user?.role === "SUPERADMIN" || user?.role === "BETTING") && (  
              <Route  
                path="coefficients"  
                element={  
                  <CoefficientCalculator  
                    setLoader={setLoader}  
                    addToast={addToast}  
                  />  
                }  
              />  
            )}  
          </Route>  
          <Route  
            path="createorupdate"  
            element={  
              <CandidateFormAddOrEdit  
                addToast={addToast}  
                setLoader={setLoader}  
                onClose={() => {}}  
              />  
            }  
          />  
        </Route>  
        {/* Catch-all route for unmatched routes */}  
        <Route path="*" element={<Navigate to="/login" replace />} />  
      </Routes>  
    </BrowserRouter>  
  );  
};  

export default App;  