import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { login } from "../../store/authSlice";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import { app } from "../../firebase";
import {
  getFirestore,
  query,
  where,
  getDocs,
  collection,
} from "firebase/firestore";

const Login = ({ addToast, setLoader }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); // Get the location object
  const db = getFirestore(app);
  const auth = getAuth(app);

  // Extract the path the user tried to access before being redirected to the login page
  const from =
    localStorage.getItem("lastRoute") ||
    location.state?.from?.pathname ||
    "/schedules/scheduled";

  useEffect(() => {
    const checkLogin = async () => {
      setLoader(true);
      const loggedInUser = JSON.parse(localStorage.getItem("user") || "{}");
      let emails = [
        "siva@bytesplash.in",
        "manoj@bytesplash.in",
        "girish@bytesplash.in",
        "bhavya@bytesplash.in",
        "harika@bytesplash.in",
        "jagan@bytesplash.in",
        "tejuspotlight@bytesplash.in",
        "siddu@bytesplash.in",
        "meghana@bytesplash.in",
        "mahi@bytesplash.in",
      ];

      if (emails.includes(loggedInUser?.emailId)) {
        if (loggedInUser?.emailId == "mahi@bytesplash.in") {
          alert("YOU ARE CHEATHER")
        }

        localStorage.clear();
        window.location.href = "/login";
      }
      if (loggedInUser?.emailId) {
        setLoader(false);
        calculateRoleAndNavigate();
      }
      setLoader(false);
    };
    checkLogin();
  }, [from, navigate, setLoader]);

  const calculateRoleAndNavigate = () => {
    let url = from ?? "";
    const loggedInUser = JSON.parse(localStorage.getItem("user") || "{}");
    if (loggedInUser?.role == "BETTING") url = "/betting/coefficients";
    else if(loggedInUser?.role=="BGVTEAM") url = "/bgv/inprogress";
    navigate(url); // Redirect to the saved route
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    if (email && password) {
      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email.toLowerCase(),
          password
        );
        const user = userCredential.user;
        const loggedInUser = {
          emailId: user.email,
          accessToken: user.accessToken,
        };

        const querySnapshot = await getDocs(
          query(
            collection(db, "BDUsers"),
            where("emailId", "==", loggedInUser.emailId)
          )
        );

        if (querySnapshot.docs.length > 0) {
          loggedInUser.role = querySnapshot.docs[0].data().role;
          dispatch(login(loggedInUser));
          localStorage.setItem("user", JSON.stringify(loggedInUser));
          setLoader(false);
          calculateRoleAndNavigate(); // Redirect to previously saved route
        } else {
          setError("User not found");
          setLoader(false);
        }
      } catch (error) {
        setError("Invalid email or password");
        setLoader(false);
      }
    } else {
      setError("Please enter both email and password");
      setLoader(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-[#0071bd] to-gray-500 px-4">
      <div className="max-w-md w-full p-8 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-center text-[#0071bd] mb-8">
          Candidates Management System
        </h2>

        {error && (
          <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-lg text-sm">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Email Address
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#0071bd] transition duration-150 ease-in-out"
              required
            />
          </div>

          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#0071bd] transition duration-150 ease-in-out"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-white bg-[#0071bd] hover:bg-[#0062a3] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0071bd] transition duration-200"
          >
            Sign In
          </button>
        </form>

        <div className="mt-6 text-center">
          <span className="text-sm text-gray-600">Don't have an account? </span>
          <a
            href="#"
            disabled={true}
            className="text-[#0071bd] hover:underline"
          >
            Sign Up
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;
