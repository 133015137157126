import React, { useState } from "react";
import jsPDF from "jspdf";
import { createUseStyles } from "react-jss";
import moment from "moment";
import image from "../static/images/Logo_Cropped.png"; // ByteSplash logo import
import sign from "../static/images/GSR_Sign.png";

import {
  loadImageAsBlob,
  loadBlobAsImage,
  compressImage,
} from "../components/utils/fileUtils";

const useStyles = createUseStyles({
  container: {
    padding: "20px",
    backgroundColor: "#f5f5f5",
    color: "#333",
    fontFamily: "Arial, sans-serif",
  },
  logo: {
    maxWidth: "150px",
    maxHeight: "80px",
  },
  form: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "15px",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "10px 0",
  },
  input: {
    padding: "12px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    transition: "border-color 0.3s",
    "&:focus": {
      borderColor: "#0071bd",
      outline: "none",
    },
  },
  label: {
    fontWeight: "bold",
    marginBottom: "5px",
    fontSize: "14px",
  },
  button: {
    gridColumn: "span 2",
    padding: "10px",
    fontSize: "16px",
    color: "#fff",
    backgroundColor: "#0071bd",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginTop: "20px",
  },
});

const RelievingLetter = ({ setLoader }) => {
  const classes = useStyles();

  // State Variables
  const [employeeName, setEmployeeName] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [designation, setDesignation] = useState("");
  const [resignationDate, setResignationDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [lastWorkingDay, setLastWorkingDay] = useState(
    moment().format("YYYY-MM-DD")
  );

  const companyName = "BYTE SPLASH TRAINING AND PLACEMENT CENTER";
  const companyAddress =
    "4th Floor, No 92/9, PR Layout, Marathahalli, Bengaluru, Karnataka - 560037";
  const footerText =
    "This is a computer-generated salary slip, so it does not require any signature";

  const generatePDF = async () => {
    setLoader(true);
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    let startY = 10;
    const lineHeight = 4.5;

    // Company Info and Logo
    if (image) {
      const imageBlob = await loadImageAsBlob(image);
      const compressedImage = await compressImage(imageBlob, {
        quality: 0.8,
        maxWidth: 800,
      });
      //   const compressedImage = await compressImage(image, 54); // Resize to max width of the logo
      const bolbTOIMage: any = await loadBlobAsImage(compressedImage);
      if (bolbTOIMage) {
        doc.addImage(bolbTOIMage, "PNG", 15, startY, 54, 16);
      }
    }

    doc.setFont("helvetica", "normal");
    doc.setFontSize(7);
    doc.setFont("helvetica", "normal");
    doc.text(`GSTIN : 29AAZFG5482H1ZJ`, 17, startY + 17);
    doc.setFontSize(10);
    const companyNameX = 75;
    doc.setFont("helvetica", "bold");
    doc.text(companyName, companyNameX, startY + 7);

    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    doc.text(companyAddress, companyNameX, startY + 11);

    startY += 30;

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    // Employee Info
    doc.text(`Name:  ${employeeName}`, 15, startY);
    startY += 5;
    doc.text(`Emp No:  ${employeeId}`, 15, startY);
    startY += 5;
    doc.text(`Designation:  ${designation}`, 15, startY);

    startY += 30;
    const pageWidth = doc.internal.pageSize.getWidth();
    const relievingText = "RELIEVING LETTER";
    const textWidth = doc.getTextWidth(relievingText);
    const remToPoints = 10; // Approximation for 1 rem in points
    const fontSize = 1 * remToPoints; // Set font size to 2 rem
    const topMargin = remToPoints * 3; // 1 rem top margin
    const startX = (pageWidth - textWidth) / 2; // Center the text horizontally
    startY = topMargin + 30;
    // Title
    doc.setFontSize(9);
    doc.setFont("helvetica", "bold");
    doc.text(relievingText, startX - 10, startY);
    startY += 10;

    // Salutation
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(`Dear`, 15, startY);
    doc.setFont("helvetica", "bold");
    doc.text(`${employeeName},`, 25, startY);
    startY += 10;

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    let textStartY = startY;

    const resignationDateFormat =
      moment(resignationDate).format("DD MMMM YYYY");
    const lastWorkingDayFormat = moment(lastWorkingDay).format("DD MMMM YYYY");

    // Breaking the content into the blocks
    const initialText = `This is with reference to your resignation dated `;
    const paragraph1_after_date = ` from the post of `;
    const paraAfterDesignation = `. The management has accepted the same with regret, and in accordance with your contract with us, you shall be released with effect from the closing hours of`;
    const paragraph1_end = `.`;

    const paragraph2 = `In case you leave us earlier than this date, the consequent shortfall in notice period shall be adjusted from your final settlement.`;
    const paragraph3 = `You will hand over charges to your superior or any other officer that the Company may designate. Your exit formalities shall be done in accordance with the laid down process of the Company.`;
    const paragraph4 = `Your contributions to the organization and its success will always be appreciated. We wish you all the best in your future career.`;

    const maxWidth = 180; // Max width of the text
    const leftMargin = 15;
    const lineHeightIncrement = 14; // Increased for more visual separation

    const drawParagraphWithBoldAndWordWrap = (
      doc,
      x,
      y,
      paragraphText,
      boldText1,
      boldText2,
      maxWidth,
      lineHeightIncrement
    ) => {
      let currentX = x;
      let currentY = y;

      const writeText = (text, isBold) => {
        doc.setFont("helvetica", isBold ? "bold" : "normal");
        const words = text.split(" ");
        for (let i = 0; i < words.length; i++) {
          const word = words[i];
          const wordWidth = doc.getTextWidth(word + " "); // Add space for word spacing
          if (currentX + wordWidth > x + maxWidth) {
            // Move to the next line if the word exceeds the max width
            currentX = x;
            currentY += 7;
          }
          doc.text(word + " ", currentX, currentY);
          currentX += wordWidth;
        }
      };

      //For paragraph1
      const paragraph1Words = paragraphText.split(" ");
      let block1 = initialText;
      let block1Width = doc.getTextWidth(block1 + " ");

      const afterBlock1 = paragraph1_after_date;
      let block2 = afterBlock1;
      let block2Width = doc.getTextWidth(block2 + " ");

      // Block 1
      writeText(block1, false);
      writeText(boldText1, true);
      writeText(afterBlock1, false);
      writeText(designation, true);
      writeText(paraAfterDesignation, false);
      writeText(" ", false);
      writeText(boldText2, true);
      writeText(paragraph1_end, false);

      currentY += lineHeightIncrement;
      currentX = x;
      writeText(paragraph2, false);
      currentY += lineHeightIncrement;
      currentX = x;
      writeText(paragraph3, false);
      currentY += lineHeightIncrement;
      currentX = x;
      writeText(paragraph4, false);
      return currentY; // Returning
    };
    textStartY = drawParagraphWithBoldAndWordWrap(
      doc,
      leftMargin,
      textStartY,
      initialText,
      resignationDateFormat,
      lastWorkingDayFormat,
      maxWidth,
      lineHeightIncrement
    );

    startY = textStartY + 17;

    // Closing
    doc.text("Best Regards,", 15, startY);
    startY += 5;
    doc.text("From Byte Splash,", 15, startY);
    startY += 5;
    doc.setFont("helvetica", "bold");
    doc.text("Sagar Guvvala", 15, startY);
    doc.setFont("helvetica", "normal");
    // Company Info and Logo
    if (sign) {
        const imageBlob = await loadImageAsBlob(sign);
        const compressedImage = await compressImage(imageBlob, {
          quality: 0.8,
          maxWidth: 800,
        });
        //   const compressedImage = await compressImage(image, 54); // Resize to max width of the logo
        const bolbTOIMage: any = await loadBlobAsImage(compressedImage);
        if (bolbTOIMage) {
          doc.addImage(bolbTOIMage, "PNG", 15, startY, 40, 14);
        }
      }
    startY += 10;
    doc.text("Founder and CEO", 15, startY + 10);

    // Save the PDF
    doc.save("relieving_letter.pdf");
    setLoader(false);
  };

  return (
    <div className={classes.container}>
      <div style={{ marginBottom: "20px" }}>
        <img src={image} alt="Company Logo" className={classes.logo} />
      </div>
      <form className={classes.form}>
        {/* Form Inputs */}
        <div className={classes.inputContainer}>
          <label className={classes.label}>Employee Name</label>
          <input
            type="text"
            value={employeeName}
            onChange={(e) => setEmployeeName(e.target.value)}
            className={classes.input}
          />
        </div>

        <div className={classes.inputContainer}>
          <label className={classes.label}>Employee ID</label>
          <input
            type="text"
            value={employeeId}
            onChange={(e) => setEmployeeId(e.target.value)}
            className={classes.input}
          />
        </div>

        <div className={classes.inputContainer}>
          <label className={classes.label}>Designation</label>
          <input
            type="text"
            value={designation}
            onChange={(e) => setDesignation(e.target.value)}
            className={classes.input}
          />
        </div>

        <div className={classes.inputContainer}>
          <label className={classes.label}>Resignation Date</label>
          <input
            type="date"
            value={resignationDate}
            onChange={(e) => setResignationDate(e.target.value)}
            className={classes.input}
          />
        </div>

        <div className={classes.inputContainer}>
          <label className={classes.label}>Last Working Day</label>
          <input
            type="date"
            value={lastWorkingDay}
            onChange={(e) => setLastWorkingDay(e.target.value)}
            className={classes.input}
          />
        </div>

        {/* Generate PDF Button */}
        <button type="button" onClick={generatePDF} className={classes.button}>
          Generate Relieving Letter
        </button>
      </form>
    </div>
  );
};

export default RelievingLetter;
