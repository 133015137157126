import React, { useState, useEffect } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { createUseStyles } from "react-jss";
import moment from "moment";
import image from "../static/images/Logo_Cropped.png";
import "../components/css/PaySlips.css";
import {loadImageAsBlob,loadBlobAsImage,compressImage} from '../components/utils/fileUtils';

const useStyles = createUseStyles({
  container: {
    padding: "20px",
    backgroundColor: "#f5f5f5",
    color: "#333",
    fontFamily: "Arial, sans-serif",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  logo: {
    maxWidth: "150px",
    maxHeight: "80px",
  },
  form: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "15px",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "10px 0",
  },
  input: {
    padding: "12px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    transition: "border-color 0.3s",
    "&:focus": {
      borderColor: "#0071bd",
      outline: "none",
    },
  },
  label: {
    fontWeight: "bold",
    marginBottom: "5px",
    fontSize: "14px",
  },
  button: {
    gridColumn: "span 2",
    padding: "10px",
    fontSize: "16px",
    color: "#fff",
    backgroundColor: "#0071bd",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginTop: "20px",
  },
  payslipContainer: {
    border: "1px solid #ccc",
    padding: "20px",
    marginTop: "20px",
    backgroundColor: "#fff",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    fontFamily: "Helvetica, Arial, sans-serif",
  },
  sectionTitle: {
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: "5px",
    borderBottom: "1px solid #eee",
    paddingBottom: "3px",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "3px",
  },
  footer: {
    marginTop: "15px",
    fontSize: "8px",
    textAlign: "center",
    color: "#777",
  },
  netAmount: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#0071bd",
    marginTop: "20px",
  },
});

const Payslip = ({ setLoader }) => {
  const classes = useStyles();

  const [logo, setLogo] = useState(image); // Set the default logo to the imported image
  const [employeeId, setEmployeeId] = useState("BS0008");
  const [designation, setDesignation] = useState("Aptitude Trainer");
  const [employeeName, setEmployeeName] = useState("Yedukondalu Malluru");
  const [pan, setPan] = useState("");
  const [doj, setDoj] = useState(moment().format("YYYY-MM-DD"));
  const [accountNo, setAccountNo] = useState("");
  const [presentDays, setPresentDays] = useState("");
  const [phWeo, setPhWeo] = useState("");
  const [totalSalary, setTotalSalary] = useState("23000.00"); // New state for Total Salary
  const [basicSalary, setBasicSalary] = useState("0.00");
  const [hra, setHra] = useState("0.00");
  const [combinedAllowances, setCombinedAllowances] = useState("0.00");
  const [lta, setLta] = useState("0.00");
  const [profTax, setProfTax] = useState("200");
  const [totalDeductions, setTotalDeductions] = useState(200);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [netPay, setNetPay] = useState(0);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [lwopAbsent, setLwopAbsent] = useState("");
  const [totalDays, setTotalDays] = useState("");

  const companyName = "BYTE SPLASH TRAINING AND PLACEMENT CENTER";
  const companyAddress =
    "4th Floor, No 92/9, PR Layout, Marathahalli, Bengaluru, Karnataka - 560037";
  const footerText =
    "This is a computer-generated salary slip, so it does not require any signature";

  const handleLogoUpload = (files) => {
    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const calculateSalaries = () => {
    const totalSalaryValue = parseFloat(totalSalary) || 0;

    const basicSalaryValue = (totalSalaryValue * 0.52).toFixed(2); // 52% of Total Salary
    const hraValue = (totalSalaryValue * 0.2).toFixed(2); // 20% of Total Salary
    const lta = (totalSalaryValue * 0.0434).toFixed(2); // 4.34%
    const combinedAllowancesValue =
      totalSalaryValue -
      (parseFloat(basicSalaryValue) +
        parseFloat(hraValue) +
        parseFloat(lta) +
        parseFloat(profTax));

    setBasicSalary(basicSalaryValue);
    setHra(hraValue);
    setCombinedAllowances(combinedAllowancesValue.toFixed(2));
    setLta(lta);
  };

  const calculateNetSalary = () => {
    const basicSalaryValue = parseFloat(basicSalary) || 0;
    const hraValue = parseFloat(hra) || 0;
    const combinedAllowancesValue = parseFloat(combinedAllowances) || 0;
    const ltaValue = parseFloat(lta) || 0;
    const profTaxValue = parseFloat(profTax) || 0;

    const totalEarningsValue =
      basicSalaryValue + hraValue + combinedAllowancesValue + ltaValue;
    const totalDeductionsValue = profTaxValue;
    const netPayValue = totalEarningsValue - totalDeductionsValue;

    setTotalEarnings(totalEarningsValue.toFixed(2));
    setTotalDeductions(totalDeductionsValue.toFixed(2));
    setNetPay(netPayValue.toFixed(2));
  };

  useEffect(() => {
    calculateSalaries();
  }, [totalSalary]); // Recalculate salaries when Total Salary changes

  useEffect(() => {
    calculateNetSalary();
  }, [basicSalary, hra, combinedAllowances, lta, profTax]); // Recalculate net pay when any salary component changes

  const generatePDF = async () => {
    setLoader(true);
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    let startY = 10;
    const lineHeight = 4.5;

    if (image) {
        const imageBlob = await loadImageAsBlob(image);
        const compressedImage = await compressImage(imageBlob, {
          quality: 0.8,
          maxWidth: 800,
        });
        //   const compressedImage = await compressImage(image, 54); // Resize to max width of the logo
        const bolbTOIMage = await loadBlobAsImage(compressedImage);
        if (bolbTOIMage) {
          doc.addImage(bolbTOIMage, "PNG", 15, startY, 54, 16);
        }
      }

    doc.setFont("helvetica", "normal");
    doc.setFontSize(7);
    doc.setFont("helvetica", "normal");
    doc.text(`GSTIN : 29AAZFG5482H1ZJ`, 17, startY + 17);
    doc.setFontSize(10);
    const companyNameX = 75;
    doc.setFont("helvetica", "bold");
    doc.text(companyName, companyNameX, startY + 7);

    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    doc.text(companyAddress, companyNameX, startY + 11);

    startY += 20;

    const remToPoints = 10; // Approximation for 1 rem in points
    const fontSize = 1 * remToPoints; // Set font size to 2 rem
    const topMargin = remToPoints; // 1 rem top margin
    const bottomMargin = remToPoints; // 1 rem bottom margin

    const pageWidth = doc.internal.pageSize.getWidth(); // Get the width of the page
    const monthText =
      "PAY SLIP FOR THE MONTH OF " +
      moment(date).format("MMMM").toUpperCase() +
      " " +
      "-" +
      " " + // Add space before the year
      moment(date).format("YYYY"); // Append the year
    const textWidth = doc.getTextWidth(monthText); // Get the width of the text

    // Calculate the starting X position to center the text
    const startX = (pageWidth - textWidth) / 2; // Center the text horizontally
    startY = topMargin + 20; // Set a Y position with top margin and extra space

    // Set the font size and style
    doc.setFontSize(fontSize); // Set the font size to 2 rem
    doc.setFont("helvetica", "normal");

    // Draw the centered text in the PDF
    doc.text(monthText, startX - 10, startY);

    startY += 10;
    // Employee Info Section
    doc.setFontSize(9);
    doc.setFont("helvetica", "normal");
    doc.text(`Emp Id : ${employeeId}`, 15, startY);
    doc.text(`Name : ${employeeName}`, 115, startY);
    startY += lineHeight;
    doc.text(`Designation : ${designation}`, 15, startY);
    doc.text(`PAN : ${pan}`, 115, startY);
    startY += lineHeight;
    doc.text(`DOJ : ${moment(doj).format("DDth MMMM YYYY")}`, 15, startY);
    doc.text(`Account No : ${accountNo}`, 115, startY);
    startY += lineHeight;
    doc.text(`Total Days : ${totalDays}`, 15, startY);
    doc.text(`Present Days : ${presentDays}`, 115, startY);
    startY += lineHeight;
    doc.text(`LWP/Absent : ${lwopAbsent}`, 15, startY);
    doc.text(`PH/WEO : ${phWeo}`, 115, startY);

    startY += 14;

    // Earnings and Deductions Headers
    doc.setFontSize(9);
    doc.setFont("helvetica", "normal");
    doc.text("Earnings", 15, startY);
    doc.text("Amount", 60, startY, { align: "right" });
    doc.text("Deductions", 115, startY);
    doc.text("Amount", 160, startY, { align: "right" });

    startY += 1;
    doc.setLineWidth(0.2);
    doc.line(15, startY, 80, startY);
    doc.line(115, startY, 180, startY);
    startY += 4;

    // Earnings and Deductions Data
    doc.setFontSize(9);
    doc.setFont("helvetica", "normal");
    doc.text("Basic Salary", 15, startY);
    doc.text(basicSalary, 60, startY, { align: "right" });
    doc.text("Prof Tax", 115, startY);
    doc.text(profTax, 160, startY, { align: "right" });
    startY += lineHeight;

    doc.text("HRA", 15, startY);
    doc.text(hra, 60, startY, { align: "right" });
    startY += lineHeight;

    doc.text("Other Allowances", 15, startY);
    doc.text(combinedAllowances, 60, startY, { align: "right" });
    startY += lineHeight;

    doc.text("LTA", 15, startY);
    doc.text(lta, 60, startY, { align: "right" });
    startY += 5;

    // Total Earnings and Deductions Lines
    doc.setLineWidth(0.2);
    doc.line(15, startY, 80, startY);
    doc.line(115, startY, 180, startY);
    startY += 1;
    doc.setFontSize(9);
    doc.setFont("helvetica", "bold");
    doc.text("Total Earnings :", 15, startY + 3);
    doc.text(totalEarnings, 60, startY + 3, { align: "right" });
    doc.text("Total Deductions :", 115, startY + 3);
    doc.text(totalDeductions, 160, startY + 3, { align: "right" });
    startY += 9;

    // Net Pay
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("Net Pay :", 15, startY);
    doc.setFont("helvetica", "bold");
    doc.setFontSize(9);
    doc.text(netPay, 60, startY, { align: "right" });
    startY += 8;

    // Rupees in words
    const formattedNetPayInWords = convertNumberToWords(parseFloat(netPay));
    doc.setFontSize(9);
    doc.setFont("helvetica", "normal");
    doc.text(
      `RUPEES : ${formattedNetPayInWords} RUPEES ONLY`.toUpperCase(),
      15,
      startY + 6
    );

    // Footer
    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    doc.text(footerText, 15, doc.internal.pageSize.height - 170);

    doc.save("payslip.pdf");
    setLoader(false);
  };

  const convertNumberToWords = (num) => {
    const single = [
      "Zero",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const double = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const teen = [
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    const thousand = ["", "Thousand", "Million", "Billion", "Trillion"];

    let number = num.toFixed(2);
    const integerPart = number.split(".")[0];
    const decimalPart = num.toFixed(2).split(".")[1];

    function toWords(n) {
      let s = "";
      let p = 0;
      while (n) {
        if (n % 1000) {
          // Only add a space if there is already content in `s`
          s =
            convertThreeDigit(n % 1000).trim() +
            (thousand[p] ? " " + thousand[p] : "") +
            (s ? " " + s : "");
        }
        n = Math.floor(n / 1000);
        p++;
      }
      return s.trim(); // Trim any excess whitespace
    }

    function convertThreeDigit(num) {
      let word = "";
      let h = Math.floor(num / 100);
      let t = Math.floor(num % 100);
      let o = Math.floor(num % 10);
      if (h) {
        word += single[h] + " Hundred";
      }
      if (t === 0) {
        word += o ? (word ? " " : "") + single[o] : "";
      } else if (t < 10) {
        word += (word ? " " : "") + single[o];
      } else if (t < 20) {
        word += (word ? " " : "") + double[t - 10];
      } else {
        word += (word ? " " : "") + teen[Math.floor(t / 10) - 2];
        word += o ? " " + single[o] : "";
      }
      return word;
    }

    let words = toWords(parseInt(integerPart));
    return words;
  };

  return (
    <div className={classes.container}>
      <div>
        {/* Show the logo if it's available */}
        <div style={{ marginBottom: "20px" }}>
          <img
            src={logo}
            alt="Company Logo"
            className="logo"
            style={{ width: "280px", height: "80px", maxWidth: "100%" }}
          />
        </div>
      </div>
      <form className={classes.form}>
        {/* Form Inputs */}
        <div className={classes.inputContainer}>
          <label className={classes.label}>Total Salary Per Month</label>
          <input
            type="number"
            value={totalSalary}
            onChange={(e) => setTotalSalary(e.target.value)}
            className={classes.input}
          />
        </div>

        <div className={classes.inputContainer}>
          <label className={classes.label}>Employee ID</label>
          <input
            type="text"
            value={employeeId}
            onChange={(e) => setEmployeeId(e.target.value)}
            className={classes.input}
          />
        </div>
        <div className={classes.inputContainer}>
          <label className={classes.label}>Employee Name</label>
          <input
            type="text"
            value={employeeName}
            onChange={(e) => setEmployeeName(e.target.value)}
            className={classes.input}
          />
        </div>
        <div className={classes.inputContainer}>
          <label className={classes.label} style={{ marginRight: "10px" }}>
            Select Month and Year
          </label>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <select
              style={{ marginRight: "10px", flex: 1 }}
              value={moment(date).month() + 1} // Increment because month() returns 0-11
              onChange={(e) => {
                const newMonth = parseInt(e.target.value, 10); // Get the selected value as an integer
                setDate(
                  moment(date)
                    .year(moment(date).year())
                    .month(newMonth - 1) // Set month index (0 - January, 1 - February, etc.)
                    .format("YYYY-MM-DD")
                );
              }}
              className={classes.input}
            >
              {Array.from({ length: 12 }, (_, index) => (
                <option key={index} value={index + 1}>
                  {moment().month(index).format("MMMM")}
                </option>
              ))}
            </select>
            <select
              style={{ flex: 1 }}
              value={moment(date).format("YYYY")}
              onChange={(e) =>
                setDate(
                  moment(e.target.value + "-01")
                    .month(moment(date).month())
                    .format("YYYY-MM-DD")
                )
              }
              className={classes.input}
            >
              {Array.from({ length: 10 }, (_, index) => (
                <option key={index} value={moment().year() - index}>
                  {moment().year() - index}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Additional input fields (continued) */}
        <div className={classes.inputContainer}>
          <label className={classes.label}>Designation</label>
          <input
            type="text"
            value={designation}
            onChange={(e) => setDesignation(e.target.value)}
            className={classes.input}
          />
        </div>
        <div className={classes.inputContainer}>
          <label className={classes.label}>PAN</label>
          <input
            type="text"
            value={pan}
            onChange={(e) => setPan(e.target.value)}
            className={classes.input}
          />
        </div>
        <div className={classes.inputContainer}>
          <label className={classes.label}>Date of Joining</label>
          <input
            type="date"
            value={doj}
            onChange={(e) => setDoj(e.target.value)}
            className={classes.input}
          />
        </div>
        <div className={classes.inputContainer}>
          <label className={classes.label}>Account Number</label>
          <input
            type="text"
            value={accountNo}
            onChange={(e) => setAccountNo(e.target.value)}
            className={classes.input}
          />
        </div>
        <div className={classes.inputContainer}>
          <label className={classes.label}>Total Days</label>
          <input
            type="text"
            value={totalDays}
            onChange={(e) => setTotalDays(e.target.value)}
            className={classes.input}
          />
        </div>
        <div className={classes.inputContainer}>
          <label className={classes.label}>Present Days</label>
          <input
            type="text"
            value={presentDays}
            onChange={(e) => setPresentDays(e.target.value)}
            className={classes.input}
          />
        </div>
        <div className={classes.inputContainer}>
          <label className={classes.label}>LWP/Absent</label>
          <input
            type="text"
            value={lwopAbsent}
            onChange={(e) => setLwopAbsent(e.target.value)}
            className={classes.input}
          />
        </div>
        <div className={classes.inputContainer}>
          <label className={classes.label}>PH/WEO</label>
          <input
            type="text"
            value={phWeo}
            onChange={(e) => setPhWeo(e.target.value)}
            className={classes.input}
          />
        </div>

        {/* Salary Calculations */}
        <div className={classes.inputContainer}>
          <label className={classes.label}>Basic Salary</label>
          <input
            type="number"
            value={basicSalary}
            readOnly // Make it read-only because it's calculated
            className={classes.input}
          />
        </div>
        <div className={classes.inputContainer}>
          <label className={classes.label}>HRA (House Rent Allowance)</label>
          <input
            type="number"
            value={hra}
            readOnly // Make it read-only because it's calculated
            className={classes.input}
          />
        </div>
        <div className={classes.inputContainer}>
          <label className={classes.label}>Combined Allowances</label>
          <input
            type="number"
            value={combinedAllowances}
            readOnly // Make it read-only because it's calculated
            className={classes.input}
          />
        </div>
        <div className={classes.inputContainer}>
          <label className={classes.label}>LTA (Leave Travel Allowance)</label>
          <input
            type="number"
            value={lta}
            onChange={(e) => setLta(e.target.value)}
            className={classes.input}
          />
        </div>
        <div className={classes.inputContainer}>
          <label className={classes.label}>Professional Tax</label>
          <input
            type="number"
            value={profTax}
            onChange={(e) => setProfTax(e.target.value)}
            className={classes.input}
          />
        </div>

        {/* Net Amount field */}
        <div className={classes.netAmount}>Net Pay: ₹ {netPay}</div>

        <button
          type="button"
          onClick={generatePDF}
          className="bg-[#0071bd] text-white font-semibold py-2 px-4 rounded shadow-md  
             transition-all duration-300 ease-in-out  
             hover:bg-[#005f8a] hover:shadow-lg transform hover:-translate-y-1  
             focus:outline-none focus:ring-2 focus:ring-[#0071bd] focus:ring-opacity-50"
        >
          Generate Payslip
        </button>
      </form>
    </div>
  );
};

export default Payslip;
